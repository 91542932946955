import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Platform from '../views/Platform.vue'
import Products from '../views/Products.vue'
import ProductDetails from '../views/ProductDetails.vue'
import Solutions from '../views/Solutions.vue'
import CustomizeService from '../views/CustomizeService.vue'
import GetADemo from '../views/GetADemo.vue'
import Cases from '../views/Cases.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Privacy from '../views/PrivacyPolicy.vue'
//import View00 from '../views/Solution7-warehouse.vue'
//import View00 from '../views/3-file.vue'
//import View00 from '../views/Solution2-tools.vue'
//import View00 from '../views/Solution1-Assets.vue'
//import View00 from '../views/Solution4-inspection.vue'
// import View00 from '../views/Solution5-cabinet.vue'

//import View00 from '../views/Solution6-Library.vue'

export const routes = [
  { path: '/:language', component: Home, alias:'/', name:Home},
  { path: '/:language/platform', name: 'Platform', component: Platform, alias: '/platform'},
  { path: '/:language/products/:id',component: Products, alias: '/products/:id'},
  { path: '/:language/products/info/:id', name: 'ProductDetails', component: ProductDetails, alias: '/products/info/:id'},
  { path: '/:language/solutions/:id', name: 'Solutions', component: Solutions, alias: '/solutions/:id'},
  { path: '/:language/service', name: 'CustomizeService', component: CustomizeService, alias: '/service'},
  { path: '/:language/get', name: 'GetADemo', component: GetADemo, alias: '/get'},
  { path: '/:language/cases', name: 'Cases', component: Cases, alias: '/cases'},
  { path: '/:language/about', name: 'About', component: About, alias: '/about'},
  { path: '/:language/contact', name: 'Contact', component: Contact, alias: '/contact'},
  { path: '/:language/privacy', name: 'Privacy', component: Privacy, alias: '/privacy'},
  // { path: '/sl',  name: 'View00', component: View00},
]
const scrollBehavior = function (to, from, savedPosition) {
  // savedPosition 会在你使用浏览器前进或后退按钮时候生效
 // 这个跟你使用 router.go() 或 router.back() 效果一致
 // 这也是为什么我在 tab 栏结构中放入了一个 点击回退 的按钮
 if (savedPosition) {
      return savedPosition
    } else {
      // 如果不是通过上述行为切换组件，就会让页面回到顶部
        return {x: 0, y: 0}
      
  }
  
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior,
  routes
})



export default router
