<template>
  <nav class="navbar container-fluid">
    <div class="nav-top">
      <div class="logo-container">
        <img src="../assets/logo-blue.svg" alt="" v-if="languageId==0">
        <img src="../assets/logo-blue-en.svg" alt="" v-else>
         <i class="site-title">{{systemTitle}}</i>
      </div>
      
      <div class="hotline">
        <i class="el-icon-phone-outline"></i>
        <span>{{hotline}}</span>
      </div>
    </div>
    <div class="nav-bottom">
      <div class="logo-item">
        <div class="logo-mobile">
          <img src="../assets/logo-blue.svg" alt="" v-if="languageId==0">
          <img src="../assets/logo-blue-en.svg" alt="" v-else>
        </div>
        <!-- <i>RFID管理系统</i> -->
      </div>
      
      <!-- <div class="button-group device-pc">
        <router-link to="/get" class="button-get-demo">免费试用</router-link>
      </div> -->
      <ul class="nav-menu nav-desktop" v-cloak>
          <li v-for="(item, index) in navMenu" :class="['menu-item', {'active':activeRoutePath===item.path}]" :key="index">
            <router-link :to="item.path" class="menu-item-title" v-if="item.clickable">
              {{item.title}}
            </router-link>
            <div v-else  @mouseleave="leave()" @mouseenter="enter(item.path)">
              <a class="menu-item-title" >{{item.title}}<i class="arrow"></i></a>
              <div :class="['submenubox',activeIndex===item.path?'submenu-show':'submenu-hide']" v-if="index===5">
                <ul :class="['submenu',{'solution-menu about-menu':index===5}]">
                  <li v-for="(item2,index2) in item.submenu" :key="index2">
                    <router-link :to="item2.path" >
                      <img :src="item2.img" alt="">
                      <p>{{item2.title}}</p>
                    </router-link>
                  </li>
                </ul>
              </div>
              <div :class="['submenubox',activeIndex===item.path?'submenu-show':'submenu-hide']" v-if="index===2">
                <ul class='submenu solution-menu'>
                  <li v-for="(item2,index2) in solutionMenus" :key="index2">
                    <router-link :to="item2.path" >
                      <img :src="item2.img" alt="">
                      <p>{{item2.title}}</p>
                    </router-link>
                  </li>
                </ul>
              </div>
              <div :class="['submenubox',activeIndex===item.path?'submenu-show':'submenu-hide']" v-if="index===1">
                <ul class='submenu products-menu'>
                  <li v-for="(item2,index2) in productMenus" :key="index2">
                    <router-link :to="item2.path" class="submenu-link">
                      
                      <div class="img-submenu">
                        <div class="cover" >
                          <p>{{viewMore}}</p>
                          <div class="bg-black"></div>
                        </div>
                        <img :src="item2.img" alt="" ref="headerSubmenuImg">
                      </div>
                      <p class="img-submenu-title">{{item2.title}}</p>
                    </router-link>
                    <ul class="menu-list">
                      <li v-for="(item3, index3) in item2.submenu" :key="index3" class="pro-item">
                        <router-link :to="item3.path" >{{item3.number}}</router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </li>
       
      <!-- <ul class="nav-menu nav-desktop" v-cloak>
        <li v-for="(item, index) in navMenu" :class="['menu-item', {'active':activeRoutePath===item.path}]" :key="index">
          <router-link :to="item.path" class="menu-item-title" v-if="item.clickable">
            {{item.title}}
          </router-link>
          <div v-else  @mouseleave="leave()" @mouseenter="enter(item.path)">
            <a class="menu-item-title" >{{item.title}}<i class="arrow"></i></a>
            <div :class="['submenubox',activeIndex===item.path?'submenu-show':'submenu-hide']" >
              <ul class='submenu products-menu' v-if="index===1">
                 <li v-for="(item2,index2) in item.submenu" :key="index2">
                  <router-link :to="item2.path" class="submenu-link">
                    <div class="cover">
                      <p>查看更多</p>
                      <div class="bg-black"></div>
                    </div>
                    <div class="img-submenu">
                      <img :src="item2.img" alt="">
                      <p>{{item2.title}}</p>
                    </div>
                  </router-link>
                  <ul class="menu-list">
                    <li v-for="(item3, index3) in item2.submenu" :key="index3" class="pro-item">
                      <router-link :to="item3.path" >{{item3.number}}</router-link>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul :class="['submenu',{'solution-menu about-menu':index===5},{'solution-menu':index===2}]" v-else>
                 <li v-for="(item2,index2) in item.submenu" :key="index2">
                  <router-link :to="item2.path" >
                    <img :src="item2.img" alt="">
                    <p>{{item2.title}}</p>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li> -->
      </ul>
      <div class="language-box">
        <div class="language-select" >
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">{{languageValue}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="(item, index) in languageOptions" :key="index" :command="item">
                {{item.value}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      
      <div class="button-group" >
         <router-link :to="getADemo[1]" class="button-get-demo">{{getADemo[0]}}</router-link>
      </div>
      <div class="menu-mobile" @click="dropMenu()">
        <img src="../assets/menu-black.svg" alt="">
      </div>
    </div>
    <MHeader :msgVal="mobileMenu" @dropback="dropbackHandler" :navMenu="navMenu" :solutionMenus="solutionMenus"/>
  </nav>
 
</template>

<script>
import icon1 from '../assets/h_icon_工具柜.png'
import icon2 from '../assets/h_icon_资产.png'
import icon3 from '../assets/h_icon_档案.png'
import icon4 from '../assets/h_icon_智能柜.png'
import icon5 from '../assets/h_icon_巡检.png'
import icon6 from '../assets/h_icon_图书馆.png'
import icon7 from '../assets/h_icon_仓储.png'
import icon8 from '../assets/h_icon_联系我们.png'
import icon9 from '../assets/h_icon_公司介绍.png'
import MHeader from './MHeader.vue'
import { getMainMenu, getSolutions, getProducts} from '../plugins/navMenu.js'
import { get } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString'

export default {
  components:{MHeader},
  data() {
    return {
      systemTitle: '',
      hotline: '',
      getADemo: ['',''],
      viewMore: '',
      activeIndex: '/',
      activeRoutePath:'/',
      mobileMenu: false,
      navMenu:'',
      timer:'',
      languageId: '',
      productMenus:[],
      solutionMenus:[],
      languageOptions: [
        { value: '中文', path: '', id: 0},
        { value: 'English', path: '/en' , id: 1},
        { value: 'Français', path: '/fr', id: 2}
      ],
      languageValue: ''
    };
  },
  methods: {
    handleCommand(command) {
      this.languageValue = command.value
      this.languageId = command.id
      const spath = command.path.replace(/\/$/g,'')
      //console.log(spath)
      sessionStorage.setItem('language', command.id)
      sessionStorage.setItem('spath',  spath)
      this.$router.push(command.path)
    },
    enter(index) {
      this.activeIndex = index
      //this.timer ='';
      const self = this;
      self.activeIndex = index
      // this.timer = setTimeout(function(){
      //    self.activeIndex = index
      // },500)
      
    },
    leave() {
      const self = this;
      //this.timer ='';
      self.activeIndex = self.$route.path
      // this.timer = setTimeout(function(){
      //   self.activeIndex = ''
      // },500)
      //console.log('鼠标离开')
    },
    dropMenu(){
      this.mobileMenu =!this.mobileMenu
    },
    dropbackHandler(key){
      this.mobileMenu = key
    }
  },
  beforeUnmount(){
    clearInterval(this.timer);
    this.timer = null;
  },
  created() {
    
    this.languageId = sessionStorage.getItem('language')
    const languageId = sessionStorage.getItem('language')
    //console.log(languageId)
    const path = this.$route.path;
    console.log(path)
    //首页的时候 根据语言切换路径
    if(path=='/'){
      if(languageId==1){
        this.$router.push('/en')
      }else if(languageId==2){
        this.$router.push('/fr')
      }
    }
    
    //更新导航栏
    const self = this;
   
    getMainMenu({lang: languageId, value: 'hMenu1;hMenu9;hMenu2;hMenu4;hMenu5;hMenu6;hMenu7;hMenu8;'})
    .then(function(data){
      //console.log(data)
      self.navMenu = data
    })

    getSolutions(languageId).then(function(data){
      //console.log(data)
      self.solutionMenus = data
    })

    getProducts(languageId).then(function(data){
      //console.log(data)
      self.productMenus = data
    })

    get('/get_value.jsp',{lang:languageId,value:"rfidTitle;hotline;rGetDemo;rMore;"}).then(function(data){
      const title = getQueryString(data.data.data[0].result)
      self.systemTitle = title.rfidTitle
      self.hotline = title.hotline
      self.getADemo[0] = title.rGetDemo
      self.viewMore = title.rMore
    })

    //导航高亮

    if(languageId==1){
      if(path=='/en'||path=='/'){
        this.activeRoutePath = '/en/'
      }else{
        this.activeRoutePath = path.match(/^\/(\w*)\/(\w*)/g)[0]
      }
    }else if(languageId==2){
      if(path=='/fr'||path=='/'){
        this.activeRoutePath = '/fr/'
      }else{
        this.activeRoutePath = path.match(/^\/(\w*)\/(\w*)/g)[0]
      }
    }else{
      if(path.match(/\/$/g)&&path!='/'){
        this.activeRoutePath = path.match(/^\/(\w*)\//g)[0].replace(/\/$/,'')
      }else{
        this.activeRoutePath = (path+'/').match(/^\/(\w*)\//g)[0].replace(/\/$/,'')
      }
    }
    //更新语言下拉框 路径
    const toPath = path.replace(/^\/(en|fr)(\/)?/g, "\/")
    this.languageOptions[0].path = toPath;
    this.languageOptions[1].path = '/en'+toPath;
    this.languageOptions[2].path = '/fr'+toPath;

    //判断中英文
    if(languageId==0){
      this.languageValue = this.languageOptions[0].value
      this.getADemo[1] = '/get'
    }else if(languageId==1){
      this.languageValue = this.languageOptions[1].value
      this.getADemo[1] = '/en/get'
    }else if(languageId==2){
      this.languageValue = this.languageOptions[2].value
      this.getADemo[1] = '/fr/get'
    }
  },
  watch: {
    'mobileMenu': function() {
      //console.log(this.mobileMenu)
      if(this.mobileMenu){
        document.body.style.overflow='hidden'
      }else{
        document.body.style.overflow='auto'
      }
    },
    'languageId':function(){
      const self = this;
      // getAllMenu(this.languageId).then(function(data){
      //   self.navMenu = data
      // })
      
    },
    '$route': function(to, from){  //刚切换语言 浏览器回退 路由变了但是language没有变
      const toR = to.path.replace(/^\/(en|fr)(?!\w)/,'')
      const fromR = from.path.replace(/^\/(en|fr)(?!\w)/,'')
      if(toR==fromR){
        //console.log(to, from)
        const toLanguage = to.params.language

        if(toLanguage=='en'){
          sessionStorage.setItem('language', 1)
          sessionStorage.setItem('spath',  '/en')
        }else if(toLanguage=='fr'){
          sessionStorage.setItem('language', 2)
          sessionStorage.setItem('spath',  '/fr')
        }else{
          sessionStorage.setItem('language', 0)
          sessionStorage.setItem('spath',  '')
        }
      }
      this.mobileMenu = false
    }
  }
}
</script>

<style lang="scss">
[v-cloak]{
    display: none;
}
.el-dropdown__popper.el-popper[role="tooltip"]{
  border: 1px solid #ebeef5 !important;
}

  .navbar{
    display: block;
    width: 80%;
    background: #fff;
    .nav-top{
      width: 100%;
      color: #235EB6;
      font-size: 1.1rem;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      text-align: right;
      height: 2rem;
      i{
        margin-right: 0.5rem;
      }
     .logo-container{
       float: left;
       height: 100%;
       .site-title{
         font-size: 1.1rem;
         vertical-align: 50%;
         margin-left: 1rem;
         font-style: normal;
        //  color: #444444;
         &::before{
            display: inline-block;
             vertical-align: middle;
             background: #dedede;
            content: "";
            width:1px;
            height: 1rem;
            margin-right: 1rem;
            margin-bottom: 0.2rem;
          }
       }
     }
    }
    .language-box{
       flex-grow: 0.5;
      .language-select{
        float: right;
        height: 1.8rem;
        padding-left:1rem;
        padding-right:0.5rem;
        margin-top: 0.9rem;
        margin-left: 1rem;
        border: 1px solid #dcdfe6;
        border-radius: 0.2rem;
        cursor: pointer;
        .el-dropdown{
          font-size: 0.75rem;
          line-height: 1.8rem;
        }
      }
    }
    
    .hotline{
      height: 100%;
      line-height: 2;
    }
    .nav-bottom{
      position: relative;
      display: flex;
      justify-content: space-between;
      .logo-item{
        // height: 3.5rem;
        padding-top: 0.4rem;
        font-size: 1.5rem;
        line-height: 2;
        display: inline-block;
        position: relative;
        .logo-mobile{
          display: none;
        }
        img{
          vertical-align: middle;
          // width: 6.95rem;
        }
        i{
          // font-style: normal;
          color: #235EB6;
          //color: #444;
          vertical-align: middle;
          letter-spacing: 4px;
          font-weight: 600;
          // &::after{
          //   display: inline-block;
          //   position: absolute;
          //    vertical-align: middle;
          //    bottom: 0;
          //    left:0;
          //   content: "";
          //   width:100%;
          //   height: 0.5rem;
          //   background-color: #235EB6;
          //   margin-right: 0.5rem;
          //   margin-bottom: 0.2rem;
          // }
        }
      }
      .nav-menu{
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        .menu-item{
          font-size: 0.9rem;
          // padding: 0 1.5rem;
          display: inline-block;
          &:last-child{
            padding-right: 0;
          }
          &:first-child{
            padding-left: 0;
          }
          &:hover {
             .menu-item-title{
               color: #235EB6; 
               border-bottom: 3px solid #235EB6;
               transition: all 0.1s ease;
               } 
              .arrow{
                background: transparent url(../assets/arrow-left-blue.svg);
                transform: rotate(180deg);
                transition: all 0.5s ease-in-out;
                background-size: 0.8rem 0.6rem;
               
              }
            }
          .arrow{
            width: 0.8rem;
            height: 0.6rem;
            margin-left: 0.2rem;
            display: inline-block;
            background: transparent url(../assets/arrow-left.svg);
            background-size: 0.8rem 0.6rem;
          }
          .menu-item-title{
            display: inline-block;
            width: 100%;
            text-align: center;
            height: 3.5rem;
            line-height: 4;
             cursor: pointer;
             overflow: hidden;
          }
          &.active .menu-item-title{
            color: #235EB6;
            border-bottom: 3px solid #235EB6;
          }
          .pro-item:hover{
            a{
               color: #235EB6;
              
            }
          }
        }
        .submenu-hide{
          display: none;
        }
        .submenu-show{
          display: block;
        }
        .submenubox{
          position: absolute;
          left: 0;
          right:0; 
          margin:auto;
          z-index: 999;
        }
        .products-menu li{ flex: 1 }
        .submenu{
          display: flex;
          padding: 1em;
          width: 100%;
          justify-content: space-between;
          background-color: #fff;
          box-shadow: 0 1.25rem 1rem 0 rgba(0, 0, 0, 0.1);
          li{
            padding: 1rem;
            overflow: hidden;
            // flex: 1;
            .submenu-link{
              width: 100%;
              display: inline-block;
              position: relative;
              
              &:hover{
                .img-submenu .cover{
                  display: block;
                }
              }
            }
            .img-submenu{
              width: 100%;
              overflow: hidden;
              img{
                max-width:100%;
              }
              .cover{
                position: absolute;
                background: rgba(0, 0, 0, 0.3);
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                display: table;
                color: #fff;
                text-align: center;
                display: none;
                max-width: 10rem;
                max-height: 10rem;
                p{
                  width: 100%;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 600;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
            .img-submenu-title{
              line-height: 1.4;
              padding-top: 0.6rem;
              padding-bottom: 0.2rem;
              font-size: 0.8rem;
              border-bottom: 1px solid #dedede;
            }
            
            .menu-list{
              padding-top: 0.5rem;
              li{
                padding: 0.3rem 0;
                cursor: pointer;
                font-size: 0.7rem;
                color: #444444;
                &:hover{
                  color: #235EB6;
                }
              }
            }
          }
        }
        .products-menu{
          .submenu-link{
            &:hover{
              color: #235EB6;
            }
          }
        }
        .solution-menu{
          flex-wrap: wrap;
          justify-content: flex-start;
          padding-bottom: 2em;
          li{
            width: 25%;
            margin-top: 1rem;
            position: relative;
            &::before{
              display: inline-block;
              content: '';
              width: 1px;
              height: 2rem;
              background-color: #dedede;
              margin-bottom: 1.5rem;
              position: absolute;
              left: 0;
              top: 20%;
            }
            &:hover{
              a{
                color: #235EB6;
              }
            }
            a{
              width: 99%;
              text-align: center;
              display: inline-block;
              img{
                margin-bottom: 0.5rem;
              }
            }
          }
        
          li:first-child{
            &::before{
              background-color: #fff;
             
            }
          }
          li:nth-child(5){
            &::before{
              background-color: #fff;
            }
          }
        }
        .about-menu{
          li{
            width: 50%;
          }
        }
      }
    }
    .button-group{
      float: right;
      height: 100%;
      margin-left: 1rem;
      padding-top: 0.9rem;
      .button-get-demo{
        padding: 0.4rem 0.5rem;
        height: 1.8rem;
        font-size: 0.75rem;
        background-color: #235EB6;
        color: #fff;
        border-radius: 0.2rem;
        display: inline-block;
      }
    }
    .device-mobile{
      display: none;
    }
    .menu-mobile{
      float: right;
      margin-top: 1.1rem;
      margin-left: .5rem;
      display: none;
    }
  }
</style>