<template>
<div class="banner">
  <!-- <div class="index_banner">
      <div class="slide">
        <img src="../assets/banner2.jpg" alt="">
      </div>
    </div> -->
   <swiper
    :slides-per-view="1"
    navigation
    :pagination="{clickable: true }"
    :loop="true" :autoplay="true"
    @swiper="onSwiper"
    observeParents:true
    observer: true
    @slideChange="onSlideChange"
    v-if="banner.length"
  >
    <swiper-slide v-for="(item, index) in banner" :key="index">
      <img :src="item" alt="" class="slide-img">
    </swiper-slide>
     <!-- <swiper-slide><router-link to="/products/2"><img src="../assets/banner2通道门.jpg" alt="" class="slide-img"></router-link></swiper-slide>
    <swiper-slide><router-link to="/products/2"><img src="../assets/banner3资产.jpg" alt="" class="slide-img"></router-link></swiper-slide>
    <swiper-slide><router-link to="/products/2"><img src="../assets/banner5工具.jpg" alt="" class="slide-img"></router-link></swiper-slide> -->
  </swiper>
</div>

</template>

<script>
import SwiperCore, { Navigation, Pagination, Autoplay} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { get, imageFile } from '../plugins/request.js';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import '../style/swiper-pagination.scss';
import '../style/swiper.navigation.scss';
//import 'swiper/components/pagination/pagination.scss';
//import 'swiper/components/navigation/navigation.scss';


// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default {
  data(){
    return {
      banner: [],
      swiperOption: {
        
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  created(){
    const self = this;
    const languageId = sessionStorage.getItem('language');

    get('/RFID-Carouselmap.jsp', {langtype:languageId}).then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      for(var i=0; i<len; i++) {
        self.banner.push(imageFile(data0[i]['skt54.skf601']))
      }
    })
  },
  methods: {
    onSwiper(swiper) {
      //console.log(swiper);
    },
    onSlideChange() {
      //console.log('slide change');
    },
  },
}
</script>

<style lang="scss">
.swiper-container{
  width: 100%;
}
.banner {
  img{
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
}
.swiper-wrapper{
  .swiper-slide{
    a,img{
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

</style>