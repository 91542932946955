<template>
<div class="m-banner">
  <!-- <div class="index_banner">
      <div class="slide">
        <img src="../assets/banner2.jpg" alt="">
      </div>
    </div> -->
   <swiper
    :slides-per-view="1"
    :pagination="{ clickable: true }"
    :loop="true" :autoplay="true"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide v-for="(item, index) in banner" :key="index">
      <img :src="item.image" alt="" class="slide-img">
      <div class="banner-text">
        <h2>{{item.title}}</h2>
        <p>{{item.desc}}</p>
      </div>
    </swiper-slide>
    <!-- <swiper-slide><img src="../assets/mbanner2.jpg" alt="" class="slide-img">
      <div class="banner-text">
        <h2>超高频RFID通道门</h2>
        <p>适用于档案、图书、资产、巡检、仓储</p>
      </div>
    </swiper-slide>
    <swiper-slide><img src="../assets/mbanner4.jpg" alt="" class="slide-img">
      <div class="banner-text">
        <h2>RFID智能工具管理系统</h2>
        <p> 让工具追溯 简单清晰高效</p>
      </div>
    </swiper-slide>
    <swiper-slide><img src="../assets/mbanner1.png" alt="" class="slide-img">
      <div class="banner-text">
        <h2>RFID资产管理方案</h2>
        <p>极大地节省了盘点的工作量，使企业更轻松、更有效地管理资产</p>
      </div>
    </swiper-slide> -->
  </swiper>
</div>

</template>

<script>
import SwiperCore, { Pagination, Autoplay} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { get, imageFile } from '../plugins/request.js';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';


// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

export default {
  data() {
    return {
      banner: []
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  created(){
    const self = this;
    const languageId = sessionStorage.getItem('language')
    get('/RFID-Carouselmap2.jsp', {langtype:languageId}).then(function(res){
      const data0 = res.data.data;
      const len = data0.length;

      for(var i=0; i<len; i++) {
        var obj = {};
        obj.image = imageFile(data0[i]['skt55.skf610']);
        obj.title = data0[i]['skt55.skf616']
        obj.desc = data0[i]['skt55.skf617']
        self.banner.push(obj)
      }
    })
  },
  methods: {
    onSwiper(swiper) {
      //console.log(swiper);
    },
    onSlideChange() {
      //console.log('slide change');
    },
  },
}
</script>

<style lang="scss">
.m-banner{
  display: none;
   margin-top: 3.5rem;
   .banner-text{
     color: #444444;
     width: 100%;
     padding: 4%;
     h2{
       font-size: 1rem;
       font-weight: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
     }
     p{
       font-size: 0.75rem;
       margin-top: 0.2rem;
       white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
     }
   }
   .swiper-pagination-bullets{
     bottom: 4.4rem;
   }
}
.swiper-container{
  width: 100%;
}

.swiper-wrapper{
  .swiper-slide{
    a,img{
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

</style>