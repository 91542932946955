<template>
<div class="container-fluid4">
  <TitleInvSubtitle :title="title"/>
  <div v-if="languageId==0" class="contact-zh">
    <div v-for="item in area" :key="item.index" class="area" >
        <p>{{item.area}}</p>
        <div class="table">
          <table class="mytable" border="0" cellspacing="0">
            <tr class="mythead">
              <th>城市</th><th>地址</th><th>电话</th>
            </tr>
            <tr v-for="(item2, index2) in item.list" :key="index2" class="mytbody">
              <td  class="td-city">{{item2.city}}</td>
              <td  class="td-address">{{item2.address}}</td>
              <td  class="td-tel">{{item2.tel}}</td>
            </tr>
          </table>
        </div>
    </div>
  </div>
  <div v-else class="contact-en">
    <img :src="wordImg[0]" alt="" class="word-desktop">
    <img :src="wordImg[1]" alt="" class="word-m">
    <div class="table-contact">
      <el-table
        :data="tableData" class="table">
        <el-table-column
          prop="address"
          :label="tableThead.country">
        </el-table-column>
        <el-table-column
          prop="phone"
          :label="tableThead.phone">
        </el-table-column>
        <el-table-column
          prop="email"
          :label="tableThead.email">
        </el-table-column>
      </el-table>
    </div>
  </div>
</div>
</template>
<script>
import TitleInvSubtitle from '../components/TitleInvSubtitle.vue'
import { AREA } from '../plugins/address.js'
import { get } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'
import wordDesktopEn from '../assets/word.png'
import wordDesktopEnM from '../assets/word-m.png'
import wordDesktopFr from '../assets/word-fr.png'
import wordDesktopFrM from '../assets/word-m-fr.png'


export default {
  name: 'ContactZh',
  components: {
    TitleInvSubtitle
  },
  data() {
    return {
      tableThead:{phone:'', country:'', email:''},
      wordImg: [],
      title: {
        title: 'contact us',
        subtitle: 'Senior expert team, provide localized professional services'
      },
      tableData: [{
        phone: '+230 58435433',
        address: 'Mauritius',
        email: 'info@chenksoft.com',
      }, {
        phone: '+65 98586237',
        address: 'Singapore',
        email: 'info@chenksoft.com',
      },{
        phone: '+86 15382379615 ',
        address: 'others',
        email: 'info@chenksoft.com',
      }],
      area: AREA,
      languageId: ''
    }
  },
  methods: {
     getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.title.title = title['cTitle2']
        self.title.subtitle = title['cTitle1']
        self.tableThead.phone = title['aPhone']
        self.tableThead.country = title['aCountry']
        self.tableThead.email = title['aEmail']
        self.tableData[2].address = title['aOthers']
      })
    },
  },
  created: function(){
    const languageId = this.languageId = sessionStorage.getItem('language')
    this.getValue({lang: languageId, value: 'cTitle1;cTitle2;aPhone;aCountry;aEmail;aOthers;'})
    if(languageId==1){
      this.wordImg[0] = wordDesktopEn;
      this.wordImg[1] = wordDesktopEnM;
    }else if(languageId==2){
      this.wordImg[0] = wordDesktopFr;
      this.wordImg[1] = wordDesktopFrM;
    }
  }
}
</script>
<style lang="scss">
.contact-en{
  padding-bottom: 4rem;
  font-size: 0.75rem;
  position: relative;
  text-align: center;
  img{
    width: 100%; 
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
  .word-desktop{
    max-width: 55rem;
  }
  .word-m{
    display:none;
  }
  .table-contact{
    padding: 1rem 2rem 2rem 2rem;
    border: 1px solid #ebeef5;
    border-radius: 0.4rem;
    font-size: 0.8rem;
  }
  .el-table th > .cell,.el-table .cell{
    font-size: 0.8rem;
  }
}
.contact-zh {
  padding-top: 4rem;
  padding-bottom: 4rem;
  font-size: 0.75rem;
  .area {
    margin-bottom: 2rem;
    p {
      font-size: 0.9rem;
      padding: 1rem 0.5rem;
    }
    .table {
      border: 1px solid #EBEEF5;
      border-radius: 4px;
      padding: 1rem 2rem;
      background: #fff;
      width: 100%;
      color: #606266;
      .mytable{
        width: 100%;
      }
      .mythead{
        font-size: 0.8rem;
        text-align: left;
        color:#909399;
        height: 2rem;
        th{
          border-bottom: 1px solid #EBEEF5;
          padding: 0 0.5rem;
        }
       
      }
      .mytbody{
        //  &:hover{
        //    td{
        //       background: #F5F7FA;
        //    }
        //   }
        td{
          height: 2.4rem;
          padding: 0 0.5rem;
         
        }
        .td-city{
          width: 20%;
        }
        .td-address{
          width: 60%;
        }
        .td-tel{
          width: 20%;
        }
        &:nth-child(odd){
          background: #f8f8f8;
        }
      }
    }

  }
}
</style>