<template>
  <div class="footer">
    <div class="container container-fluid2">
      <div class="footer-top">
        <ul class="column">
          <li class="column-link">
            <ul class="item">
              <div class="title">{{navTitle[0]}}</div>
              <template v-for="(item, index) in navMenu" :key="index">
                <li  v-if="item.clickable">
                  <router-link :to="item.path" >{{item.title}}</router-link>
                </li>
                <template v-if="index==5">
                  <li v-for="(item2, index2) in item.submenu" :key="index2">
                    <router-link :to="item2.path" >{{item2.title}}</router-link>
                  </li>
                </template>
              </template>
            </ul>
          </li>
          <li class="column-link">
            <ul class="item">
              <div class="title">{{navTitle[1]}}</div>
              <li v-for="(item, index) in products" :key="index">
                <router-link :to="item.path">{{item.title}}</router-link>
              </li>
            </ul>
          </li>
          <li class="column-link">
            <ul class="item">
              <div class="title">{{navTitle[2]}}</div>
              <li v-for="(item, index) in solutions" :key="index">
                <router-link :to="item.path">{{item.title}}</router-link>
              </li>
            </ul>
          </li>
          <li class="column-link contact">
            <ul class="item">
              <div class="title">{{navTitle[3]}}</div>
              <li class="">
                <div class="img-box"><img src="../assets/address.png" alt=""> </div>
                <div class="text-box"><span>{{navTitle[6]}}{{navTitle[7]}}</span></div>
                </li>
              <li class=""><div class="img-box"><img src="../assets/phone.png" alt=""></div><div class="text-box"><span>{{hotline}}</span></div></li>
              <li class=""><div class="img-box"><img src="../assets/email.png" alt=""></div><div class="text-box"><span>{{email}}</span></div></li>
            </ul>
          </li>
          <li class="column-link privacy-item">
             <ul class="item">
              <div class="title" v-if="languageId==0">{{navTitle[4]}}</div>
              <template  v-else>
                <div class="title">Follow us</div>
                <li v-if="languageId!=0" class="social-icon"> 
                  <a href="https://www.facebook.com/chenksoft" target="_blank"><img src="../assets/Social_facebook.png"></a>
                  <a href="https://twitter.com/chenksoft" target="_blank"><img src="../assets/Social_Twitter.png"></a>
                  <a href="https://www.linkedin.com/company/72265600" target="_blank"><img src="../assets/Social_Linkedin.png"></a>
                </li>
              </template>
              <li> <router-link :to="privacyPath">{{navTitle[5]}}</router-link></li>
              <li class="wx" v-if="languageId==0">
                <p>关注公众号</p>
                <img src="../assets/公众号.png" alt="">
              </li>
            </ul>
            <!-- <ul class="item wx">
              <div class="title">关注公众号</div>
              <img src="../assets/公众号.png" alt="">
              <p>获取最新产品及行业动态</p>
            </ul> -->
          </li>
        </ul>
      </div>
      <div class="footer-bottom">
        <img src="../assets/logo-w.svg" alt="" v-if="languageId==0">
        <img src="../assets/logo-w-en.svg" alt="" v-else>
        <p>{{copy[0]}}<span class="href" @click="toRescue">{{copy[1]}}</span> <span>{{copy[2]}}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import { getSolutions, getProMenu, navMenu } from '../plugins/navMenu.js'
import { get,imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString'

export default {
  name: 'Footer',
  mounted: function(){
    window.scrollTo(0, 0)
  },
  data(){
    return {
      hotline: '',
      email: '',
      navMenu, 
      solutions:'',
      copy:['', '', ''],
      navTitle: ['','','','',''],
      products: '',
      privacyPath: sessionStorage.getItem('spath')+'/privacy',
      languageId: sessionStorage.getItem('language')
    }
  },
  methods: {
    toRescue(){
      window.open('https://beian.miit.gov.cn/')
    },
    async getSolutions(){
      let menu = await getSolutions(this.languageId) 
      this.solutions = menu
    },
    async getProMenu(){
      let menu = await getProMenu(this.languageId) 
      this.products = menu
    }
  },
  created: function(){
    this.getSolutions()
    this.getProMenu()
    const self = this;
    get('/get_value.jsp',
    {
      lang:this.languageId,
      value:"rFooterM1;rFooterM2;rFooterM3;rFooterM4;rFooterM5;rFooterCopy1;rFooterCopy2;rFooterCopy3;rFooterM6;addressHZ1;addressHZ2;hotline;email;"}).
    then(function(data){
      const title = getQueryString(data.data.data[0].result)
      
      self.navTitle[0] = title.rFooterM1
      self.navTitle[1] = title.rFooterM2
      self.navTitle[2] = title.rFooterM3
      self.navTitle[3] = title.rFooterM4
      self.navTitle[4] = title.rFooterM5
      self.navTitle[5] = title.rFooterM6
      self.navTitle[6] = title.addressHZ1
      self.navTitle[7] = title.addressHZ2
      self.copy[0] = title.rFooterCopy1
      self.copy[1] = title.rFooterCopy2
      self.copy[2] = title.rFooterCopy3
      self.hotline = title.hotline.split(':')[1]
      self.email = title.email.split(':')[1]
    })
  }
}
</script>

<style lang="scss">
.footer {
  background: #181F37;
  width: 100%;
  font-size: 0.7rem;
  .container {
    width: 100%;
    color: #fff;
    padding-top: 2rem;
    padding-bottom: 1rem;
    .footer-top{
      border-bottom: 1px solid #303649;
      padding-bottom: 1rem;
      .column{
        color: #fff;
        display: flex;
        justify-content:space-between;
        .contact{
          width: 12rem;
          img,span{
            display: inline-block;
            line-height: 1.6;
            vertical-align: top;
          }
          img{
            vertical-align: -10%;
            // margin-right: 0.5rem;
            // margin-top: 0.1rem;
          }
          li{
            margin-bottom: 1rem;
          }
        }
        .column-link:last-child(){
          margin-right: 0;
        }
         .privacy-item{
            .wx{
               
              img{
                margin-top: 0.5rem;
                width: 4.3rem;
              }
            }
          }
        .column-link{
          // display: inline-block;
          vertical-align: top;
          // margin-right: 12%;
          &:last-child{
            margin-right: 0%;
          }
          .social-icon{
            a{
              margin-right: 0.5rem;
            }
          }
          .item{
            .title{
              font-size: 1.2rem;
              margin-bottom: 1rem;
            }
            &.wx{
              text-align: center;
              p{
                color: #9EA5BD;
              }
              img{
                margin-bottom: 1rem;
              }
            }
            li{
               font-size: 14px;
               line-height: 2;
               color:#9EA5BD;
               .img-box{
                 display: inline-block;
                 width: 15%;
               }
               .text-box{
                 width: 85%;
                 display: inline-block;
               }
              a{
                font-size: 14px;
                color:inherit;
                &:hover{
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
      
    }
    .footer-bottom{
      padding-top: 1rem;
      p{
        display: inline-block;
        line-height: 1.7;
      }
      img{
          vertical-align: middle;
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
      }
      .href{
        text-decoration: underline;
        cursor: pointer;
        padding: 0 1rem;
      }
      
    }
  }
    
}
</style>