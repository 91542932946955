<template>
  <div class="our-producst">
    <TitleInvSubtitle :title="title"/>
    <ul class="pro-wrapper container-fluid2">
      <li class="pro-li" v-for="(item,index) in data0" :key="index">
        <router-link :to="'/products/'+item.id">
          <img :src="item.img" alt="" class="pro-img">
          <div class="text">
            <h3>{{item.title}}</h3>
            <p>{{item.desc}}</p>
            <p class="all"> <span>{{viewAll}}</span><img src="../assets/arrow-right.svg" alt="" class="more"></p>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import TitleInvSubtitle from './TitleInvSubtitle.vue'
import { get,imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString'

export default {
  data(){
    return {
      title: {
        title: '',
        subtitle: ''
      },
      viewAll: '',
      data0: [
        { title: '', img:'', id: '', desc:'' }
      ]
    }
  },
  components: {
    TitleInvSubtitle
  },
  created(){
    const self = this;
    const languageId = sessionStorage.getItem('language')
    get('/RFID-Menu.jsp', {langtype:languageId}).then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      for(var i =0; i<len; i++){
       let obj= {};
       obj.title = data0[i]['skt2.skf9']
       obj.img = imageFile(data0[i]['skt2.skf585'])
       obj.id = data0[i]['skt2.skf8']
       obj.desc = data0[i]['skt2.skf584']
       self.data0[i] = obj
      }
    })

    get('/get_value.jsp',{lang:languageId,value:"rHTitle1;rHDesc1;rViewAll;"}).then(function(data){
      const title = getQueryString(data.data.data[0].result)
      console.log(title)
      self.title.title = title.rHTitle1
      self.title.subtitle = title.rHDesc1
      self.viewAll = title.rViewAll
    })
  }
}
</script>

<style lang="scss">
.our-producst{
  width: 100%;
  background:#f8f8f8;
  .pro-wrapper{
   .pro-li{
      background: #fff;
      position: relative;
      width: 46%;
      padding-top: 5%;
      min-height: 16rem;
      // max-height: 30rem;
      margin: 2%;
      display: inline-block;
      cursor: pointer;
      .pro-img{
        width: 100%;
        vertical-align: bottom;
      }
      a{
        display: inline-block;
        width: 100%;
        height: 100%;
      }
      .text{
        padding: 1rem;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 2;
        h3{
          font-weight: normal;
          font-size: 1.5rem;
          margin-bottom: 0.2rem;
        }
        p{
          font-size: 0.8rem;
          color: #444444;
          line-height: 2;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          // width: 50%;
        }
        .all{
          .more{
            width: 1rem;
            vertical-align: -12%;
          }
        }
       
      }
      &:hover{
        box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}

</style>