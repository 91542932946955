<template>
  <div class="cases-1 container-fluid3">
    <TitleInvSubtitle :title="title" />
    
    <div v-if="languageId==0">
      <div class="items">
        <ul  >
          <li v-for="(item, index) in items" :key="index" class="item-box"><img :src="item.logo" alt=""></li>
          </ul>
      </div>
      <ul class="cases-all cases-desktop" v-for="(item, index) in companys" :key="index">
        <li :key="item.index">
          <div class="title">{{item.name}}</div>
          <ul  class="layer2">
            <li v-for="item2 in item.company" :key="item2.index">
              <span v-for="item3 in item2" :key="item3.index">{{item3}}</span>
            </li>
          </ul>
          </li>
      </ul>
      <ul class="cases-mobile" v-for="(item, index) in companysM" :key="index">
        <li >
          <div class="title">{{item.name}}</div>
          <ul  class="layer2">
            <li v-for="(item2, index2) in item.company" :key="index2">{{item2}}</li>
          </ul>
        </li>
      </ul>
    </div>
     
    <Cases2  v-else />
  </div>
</template>

<script>
import TitleInvSubtitle from './TitleInvSubtitle.vue'
import company from '../plugins/company.js'
import Cases2 from './Cases2.vue'
import { get } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

import logo1 from '../assets/case-阿斯.png';  
import logo2 from '../assets/case-月星.png';  import logo4 from '../assets/case-银江2.png'; import logo5 from '../assets/case-浙江.png';
import logo6 from '../assets/case-boe.png'; import logo7 from '../assets/case-知味观.png'; import logo8 from '../assets/case-bank.png';
import logo9 from '../assets/case1-宁波.png'; import logo10 from '../assets/case2-大搜车.png';
import logo11 from '../assets/case3-工业.png'; import logo12 from '../assets/case4-华大.png';
import logo13 from '../assets/case5-华东理工.png'; import logo14 from '../assets/case6-华东医药.png';
import logo15 from '../assets/case7-创维.png'; import logo16 from '../assets/case8-京.png';
import logo18 from '../assets/case10-中石化.png';import logo17 from '../assets/case-p.png';
import logo19 from '../assets/case12-中国铁建.png'; import logo20 from '../assets/case13-中国石油.png';
import logo21 from '../assets/case15-中国地质大学.png'; import logo22 from '../assets/case16-中国东方航空.png';
import logo23 from '../assets/case17-中储粮.png'; import logo24 from '../assets/case18-上海.png';
import logo25 from '../assets/case19-二所.png'; import logo26 from '../assets/case-w.png'; 

export default {
  name: 'CCaseZh',
  components: {
    TitleInvSubtitle,
    Cases2
  },
  data() {
    return {
      title: {
        title: '',
        subtitle: ''
      },
      companys: company.deal,
      companysM: company.original,
      items: [
        { logo: logo1 },{ logo: logo4 },{ logo: logo5 },{ logo: logo19 },{ logo: logo20 },
        { logo: logo22 },{ logo: logo21 },{ logo: logo23 },{ logo: logo24 },{ logo: logo25 },
        { logo: logo6 },{ logo: logo7 },{ logo: logo8 },{ logo: logo9 },{ logo: logo10 },
        { logo: logo11 },{ logo: logo14 },{ logo: logo15 },{logo: logo26},
        { logo: logo16 },{ logo: logo18 },{ logo: logo2 },{ logo: logo12 },{ logo: logo13 }
      ],
      languageId: ''
    }
  },
  created() {
    const self = this;
    const languageId = this.languageId = sessionStorage.getItem('language')
    get('/get_value.jsp',{lang:languageId,value:"rHTitle5;rHDesc5;"}).then(function(data){
      const title = getQueryString(data.data.data[0].result)
      self.title.title = title.rHTitle5
      self.title.subtitle = title.rHDesc5
    })
  }
}
</script>
<style lang="scss">
.cases-1 {
   padding-top: 2rem;
   padding-bottom: 2rem;
   .cases-mobile{
     display: none;
     font-size: 0.7rem;
     .title{
       font-size: 1rem;
     }
   }
   img{
    width: 100%; 
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
  .items {
    .item-box{
      width: 15.66%;
      display: inline-block;
      padding: 0.5%;
      margin: 0.5%;
      border: 1px solid #EBEEF5;
      border-radius: 4px;
      img{
        width: 100%;
      }
    }
  }
  .tabs {
    margin-top: 2rem;
    border: 1px solid #EBEEF5;
   .el-tabs__header {
     padding-top: 2rem;
     padding-bottom: 2rem;
   }
    .el-tabs__item {
      font-size: 0.8rem;
    }
    .el-tabs__content {

      ul {
        margin: 2rem;
        li{
          height: 2.5rem;
          line-height: 2.5rem;
          span {
            display: inline-block;
            text-align: center;
            width: 32%;
            padding-left: 0.5rem;
          }
          &:hover:hover {
            background: #f6f7fb;
          }
          &:nth-child(even) {
            background: #fafafa;
          }
        }
      }
    }
  }
  .cases-all{
    width: 100%;
    font-size: 0.8rem;
    padding-top: 4rem;
    li{
      .title{
        padding-left: 0.5rem;
        font-size: 0.9rem;
        font-weight: bold;
      }
      .layer2{
        padding-top: 1rem;
        width: 100%;
        padding: 0.5%;
        margin: 0.5%;
        border-radius: 4px;
        border: 1px solid #EBEEF5;
        li{
          line-height: 2;
          &:nth-child(odd){
            background: #f8f8f8;
          }
          span{
            display: inline-block;
            vertical-align: top;
            width: 33.3%;
            padding: 1%;
          }
        }
      }
    }
  }
}
.cases-mobile{
  width: 100%;
  li{
    .title{
      color: #1F87E8;
      background: #ecf5ff;
      padding: 0.5rem;
      font-size: 1.1rem;
      margin: 0.5rem 0 1rem;
      font-weight: 400;
    }
    .layer2{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li{
          width: 48%;
          padding: 0.5rem 0;
        }
      }
  }
  
}

</style>