<template>
  <div  v-cloak>
    <BackTop />
    <SideNavBar />
    <router-view :key="key" />
  </div>
</template>

<script>
import BackTop from '@/components/BackTop.vue'
import SideNavBar from '@/components/SideNavBar.vue'

export default {
  name: 'App',
  components: {
   BackTop,
   SideNavBar
  },
  computed: {
    key(){
      return this.$route.path
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Arial,"Microsoft YaHei",sans-serif,"Noto Sans","Helvetica Neue","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
a:link,a:visited,a:hover,a:active{
  color: initial;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
html{
  //1rem = 20px
  font-size: 125%;
  //font-size: 20px;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  color: #222;
  -webkit-font-smoothing: antialiased;
}
ul, li{
  list-style: none;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.clearfix {
  *zoom: 1;

  &::after{ /*伪元素是行内元素 正常浏览器清除浮动方法*/
    content: "";
    display: block;
    height: 0;
    clear:both;
    visibility: hidden;
  }
}
.container-fluid{
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
.container-fluid2{
  padding-right: 5%;
  padding-left: 5%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
.container-fluid3{
  padding-right: 10%;
  padding-left: 10%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
.container-fluid4{
  padding-right: 15%;
  padding-left: 15%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
//防止图片缩放变糊
// img, .bgImg{
//   image-rendering: -moz-crisp-edges;
// 	image-rendering: -o-crisp-edges;
// 	image-rendering: -webkit-optimize-contrast;
// 	image-rendering: crisp-edges;
// 	-ms-interpolation-mode: nearest-neighbor;
//   //-ms-interpolation-mode: bicubic; 
// }

//隐藏百度对话框
#newBridge{
  display: none !important;
}

</style>
