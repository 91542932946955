export const AREA = [
  {
    area: '华东地区',
    list: [
      {
        city: '杭州（总部）',
        address: '杭州市西湖区万塘路252号计量大厦611室',
        tel: '400-6990-220'
      },
      {
        city: '上海',
        address: '上海市黄浦区西藏南路760号安基大厦2005-2006',
        tel: '400-6990-220'
      },
      {
        city: '南京',
        address: '南京市大周路32号软件谷科创城D1栋502室',
        tel: '400-6990-220'
      },
      {
        city: '福州',
        address: '福州市鼓楼区铜盘路凤舞家园1号楼908室',
        tel: '400-6990-220'
      },
      {
        city: '厦门',
        address: '厦门市湖里区江头建材市场东二区207',
        tel: '400-6990-220'
      }
    ]
  },
  {
    area: '华中地区',
    list: [
      {
        city: '武汉',
        address: '武汉市东湖新技术开发区关山二路特一号国际企业中心追日楼302-A',
        tel: '400-6990-220'
      },
      {
        city: '合肥',
        address: '合肥市高新区深港数字化产业园10栋5楼',
        tel: '400-6990-220'
      },
      {
        city: '南昌',
        address: '南昌市高新区紫阳大道2999号紫阳明珠D栋17楼',
        tel: '400-6990-220'
      },
      {
        city: '郑州',
        address: '郑州市金水区南阳路北仓中里2号院内',
        tel: '400-6990-220'
      },
      {
        city: '长沙',
        address: '长沙市开福区双拥路168号四方嘉丽大厦905室',
        tel: '400-6990-220'
      }
    ]
  },
  {
    area: '华南地区',
    list: [
      {
        city: '广州',
        address: '广州市天河区天寿路25号b座502室',
        tel: '400-6990-220'
      },
      {
        city: '深圳',
        address: '深圳市龙华区龙华街道三联社区富联一区一巷 OTO创客商务中心217',
        tel: '400-6990-220'
      },
      {
        city: '海口',
        address: '海口市美兰区海甸二东路水岸星城A区A2栋2405室',
        tel: '400-6990-220'
      },
      {
        city: '南宁',
        address: '南宁市青秀区仙葫大道金地世家南区12栋一单元',
        tel: '400-6990-220'
      }
    ]
  },
  {
    area: '华北地区',
    list: [
      {
        city: '北京',
        address: '北京市朝阳区广渠门外大街8号优士阁大厦B座2910',
        tel: '400-6990-220'
      },
      {
        city: '天津',
        address: '天津市河东区海河东路路恒景园8幢401',
        tel: '400-6990-220'
      },
      {
        city: '太原',
        address: '太原市旱西关26号晋福鑫园5楼501室',
        tel: '400-6990-220'
      },
      {
        city: '济南',
        address: '济南市历下区二环东路5001号和瑞中心B座907室',
        tel: '400-6990-220'
      },
      {
        city: '青岛',
        address: '青岛市市北区会昌路4号6号楼1单元201',
        tel: '400-6990-220'
      }
    ]
  },
  {
    area: '西北地区',
    list: [
      {
        city: '西安',
        address: '西安市雁塔区大寨路华洲城熙悦都',
        tel: '400-6990-220'
      },
      {
        city: '银川',
        address: '银川市兴庆区光明巷63号楼三单元401',
        tel: '400-6990-220'
      },
      {
        city: '乌鲁木齐',
        address: '乌鲁木齐市水磨沟区南湖北路晟和苑188-24号',
        tel: '400-6990-220'
      },
      {
        city: '兰州',
        address: '兰州市城关区嘉峪关东路盛业阳光雅筑2号楼1单元1303室',
        tel: '400-6990-220'
      }
    ]
  },
  {
    area: '西南地区',
    list: [
      {
        city: '成都',
        address: '成都市金牛区抚琴西路181号-群益大厦S402室',
        tel: '400-6990-220'
      },
      {
        city: '重庆',
        address: '重庆市九龙坡区谢家湾正街25号大鼎城市广场B区2幢',
        tel: '400-6990-220'
      },
      {
        city: '昆明',
        address: '昆明市官南大道1866号106室',
        tel: '400-6990-220'
      }
    ]
  },
  {
    area: '东北地区',
    list: [
      {
        city: '沈阳',
        address: '沈阳市和平区北大街光大大厦1105室',
        tel: '400-6990-220'
      },
      {
        city: '哈尔滨',
        address: '哈尔滨市南岗区南兴街8号辰能溪树庭院C14栋2012室',
        tel: '400-6990-220'
      },
      {
        city: '长春',
        address: '长春市宽城区沈铁盛华庭小区15栋二单元1507',
        tel: '400-6990-220'
      }
    ]
  }
]