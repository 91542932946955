<template>
<div class="platform-1 container-fluid3">
  <div class="text"> 
    <h1>{{msg.title33}}</h1>
    <h2 class="title title-line" >
      {{msg.title}}
    </h2>
    <p>{{msg.subtitle}}</p>
  </div>
  <div class="picture">
    <img src="../assets/platform-peitu2.png" alt="" >
  </div>
 
</div>
</template>

<script>

import { get } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

  export default {
    name: 'PFRecommend',
    components: {
     
    },
    data() {
      return{
        msg: {
          title33: '',
          title: '',
          subtitle: ''
        },
      }
      
    },
    methods: {
      getValue(params) {
        const self = this;
        get('/get_value.jsp', params).then(function(res){
          //console.log(res.data.data[0].result)
          const title = getQueryString(res.data.data[0].result)
          self.msg.title33 = title['p1Title1']
          self.msg.title = title['p1Title2']
          self.msg.subtitle = title['p1Title3']
        })
      }
    },
    created: function(){
      const languageId = sessionStorage.getItem('language')
      this.getValue({lang: languageId, value: 'p1Title1;p1Title2;p1Title3;'})
    }
  }
</script>

<style lang="scss">
.platform-1{
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 0.8rem;
  
  .text {
    padding-right: 2%;
    display: inline-block;
    vertical-align: middle;
    width: 55%;
    padding: 0 1%;
    margin-right: 5%;
    h1 {
      font-size: 1.5rem;
      font-weight: normal;
      margin-bottom: 1rem;
    }
    .title {
      font-weight: normal;
      font-size: 1rem;
      position: relative;
      margin-bottom: 2rem;
      opacity: 0.8;
      &::after, &::before{
        content: '';
        display: block;
        background: #1F87E8;
        border-radius:0.4rem;
        height: 0.2rem;
        bottom: -1rem;
        position: absolute;
      }
      &::before{
        width: 6rem;
      }
      &::after {
        width: 0.2rem;
        left: 0.65rem;
      }
    }
    p {
      line-height: 1.7;
      padding-top: 1rem;
    }
  }
  .picture{
    width: 40%;
    display: inline-block;
    vertical-align: middle;
    img{
      width: 100%;
    }
  }
  
}
</style>