<template>
  <div class="products-item">
    <div class="container-fluid3">
      <ProThumb />
      <div class="products-top ">
        <div class="left">
          <!-- <div class="pro-main"><img :src="mainImg" alt="" id="main-img"></div> -->
          <div class="pro-main">
             <video :src="mainImg.view"
            style="width: 100%; margin-top:110px;"  controls v-if="mainImg.isVedio"></video>
            <img :src="mainImg.view" alt="" id="main-img" v-else >
          </div>
          <div class="thumb thumb-desktop">
            <ul class="pro-list" v-if="thumb">
              <li :class="activeIndex==index?'active':''" v-for="(item, index) in thumb" :key="index" @click="selectimg(item,index)">
                <img :src="item.cover" alt="">
              </li>
              <!-- <li :class="activeIndex==1?'active':''" @click="selectimg(require('../assets/details-ex-1.png'),1)"><img src="../assets/details-ex-1.png" alt=""></li>
              <li :class="activeIndex==2?'active':''" @click="selectimg(require('../assets/details-ex-2.png'),2)"><img src="../assets/details-ex-2.png" alt=""></li>
              <li :class="activeIndex==3?'active':''" @click="selectimg(require('../assets/details-ex-3.png'),3)"><img src="../assets/details-ex-3.png" alt=""></li>
              <li :class="activeIndex==4?'active':''" @click="selectimg(require('../assets/details-ex-4.png'),4)"><img src="../assets/details-ex-4.png" alt=""></li> -->
            </ul>
          </div>
        </div>
        <div class="right" v-if="info">
          <div class="title">
            <h2>{{info.title}}</h2>
          </div>
          <div class="info">
            <div class="list">
              <p class="subtitle">{{titleParams[0]}}</p>
              <p v-html="info.desc"></p>
            </div>
            <div class="list" v-if="feature.length">
              <p class="subtitle">{{titleParams[1]}}</p>
              <ul class="points">
                <li v-for="(item, index) in feature" :key="index">{{item}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
     
    </div>
    <div class="products-info container-fluid3" v-if="parameter.length">
      <div class="headline">{{titleParams[2]}}</div>
      <ul class="info">
        <li class="item" v-for="(item, index) in parameter" :key="index">
          <div class="title">{{item.title}}</div>
          <ul class="info-list">
            <li v-for="(item2, index2) in item.content" :key="index2" :class="{'langlabel': languageId!=0}">
              <span class="label">{{item2.title}}</span>
              <span class="text">{{item2.info}}</span>
            </li>
          </ul>
        </li>
      </ul>
     
    </div>
    <div class="products-relative container-fluid3" v-if="relativeProducts.length">
      <div class="headline">{{titleParams[3]}}</div>
      <ul class="relative-pro">
        <li class="item" v-for="(item, index) in relativeProducts" :key="index">
          <router-link :to="path+item.id">
            <img :src="item.img" alt="">
            <p>{{item.title}}</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import ProThumb from './ProThumb.vue'
import getQueryString from '../plugins/getQueryString.js'

export default {
  data(){
    return{
      activeIndex: 0,
      mainImg: '',
      info:{
        title: '',
        desc: ''
      },
      feature:[],
      thumb:[
        // {
        //   isVedio: true,
        //   cover: 'https://v1.cecdn.yun300.cn/site_1712280217/UHF_LED.jpg',
        //   view: 'https://v1.cecdn.yun300.cn/site_1712280217/UHF_LED.mp4'
        // }
      ],
      parameter:[],
      relativeProducts: [],
      languageId: '',
      titleParams: [],
      path: ''
    }
  },
  components:{
    ProThumb
  },
  methods: {
    selectimg(url, key){
      this.activeIndex = key
      this.mainImg = url
    }
  },
  created(){
    const self = this;
    const id = this.$route.params.id;
    this.languageId = sessionStorage.getItem('language')
    const languageId = sessionStorage.getItem('language')
    this.path = sessionStorage.getItem('spath')+'/products/info/';

    get('/RFID-ProductDetails0.jsp', {langtype:languageId,productid:id}).then(function(res){
      const data0 = res.data.data;
      self.info.title = data0[0]['skt45.skf501']
      self.info.desc = data0[0]['skt45.skf494']
    })
    get('/RFID-ProductDetails1.jsp', {langtype:languageId,productid:id}).then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      let arr = []
      for(var i=0; i<len; i++) {
        arr[i] = data0[i]['skt46.skf510']
      }
      self.feature = arr
    })
    get('/RFID-ProductDetail.jsp', {langtype:languageId,productid:id}).then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      //let arr = []
      for(var i=0; i<len; i++) {
        let obj = {}
        obj.isVedio = data0[i]['isVideo']
        if(data0[i]['isVideo']){
          const temp = data0[i]['videoandcover'].split(';')
          obj.cover = imageFile(temp[1])  
          obj.view = imageFile(temp[0])
        }else{
          obj.cover = imageFile(data0[i]['productimg'])  
          obj.view = imageFile(data0[i]['productimg'])
        }
        self.thumb.push(obj)
        //arr[i] = obj
      }
      //console.log(arr)
      //self.thumb = arr
      //self.mainImg = arr[0]
      console.log(self.thumb)
      self.mainImg = self.thumb[0]
    })
    get('/RFID-ProductDetails3.jsp', {langtype:languageId,productid:id}).then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      let arr = []
      for(var i=0; i<len; i++) {
        let sp1 = []
        let obj = {}
        let arr2 = []
        obj.title = data0[i]['skt47.skf519']
        sp1 = data0[i]['skt47.skf527'].split(/\&\s*\|\s*\|\s*\&/)
        const lenSp1 = sp1.length;
       
        for(var j=0; j<lenSp1; j++){
          let obj2 = {}
         // const newIndex = parseInt(j/2)
          if(j%2!==0){
            obj2.title = sp1[j-1]
            obj2.info = sp1[j]
            arr2.push(obj2)
          }
        }
        obj.content = arr2;
        arr.push(obj)
      }
      self.parameter = arr
    })
    get('/RFID-ProductDetails4.jsp', {langtype:languageId,productid:id}).then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      let arr = []
      for(var i=0; i<len; i++) {
        let obj = {}
        obj.title = data0[i]['skt48.skf541']
        obj.img = imageFile(data0[i]['skt48.skf531'])
        obj.id = data0[i]['skt48.skf529']
        arr.push(obj)
      }
      //console.log(arr)
      self.relativeProducts = arr
    })
    get('/get_value.jsp',{lang:languageId,value:"rProIntroduction;rProFeatures;rProParameters;rRelatedPro;"}).then(function(data){
      const title = getQueryString(data.data.data[0].result)
      self.titleParams[0] = title.rProIntroduction
      self.titleParams[1] = title.rProFeatures
      self.titleParams[2] = title.rProParameters
      self.titleParams[3] = title.rRelatedPro
    })
  }
}
</script>

<style lang="scss">
.products-item{
  background: #f8f8f8;
  padding-top: 3rem;
  padding-bottom: 3rem;
  line-height: 1.5;
  .products-top{
    width: 100%;
    .left{
      width: 500px;
      max-width: 600px;
      display: inline-block;
      margin-right: 2%;
      vertical-align: top;
      overflow: hidden;
      .pro-main{
        width:100%;
        height: 500px;
        background: #fff;
        img{
          width: 100%;
        }
      }
    }
    .right{
      width: calc(98% - 500px);
      display: inline-block;
      background: #fff;
      vertical-align: top;
      padding: 1rem 2rem 0 2rem;
      min-height: 500px;
      .title{
        h2{
          font-weight: normal;
          font-size: 30px;
          line-height: 2.2;
          border-bottom: 1px dashed #dedede;
        }
      }
      .info{
        padding: 1rem 0;
        font-size: 0.8rem;
        .list{
          margin-bottom: 1rem;
          &:last-child{
            margin-bottom: 0;
          }
          .subtitle{
            font-size: 0.9rem;
            color: #999999;
            margin-bottom: 0.5rem;
          }
          .points{
            li{
              display: block;
              line-height: 2;
              &::before{
                content: '';
                width: 0.4rem;
                height: 0.4rem;
                border-radius: 50%;
                background: #235EB6;
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
    }
    
  }
  .thumb{
    .pro-list{
      margin-top: 1rem;
      li{
        display: inline-block;
        width: 4rem;
        height: 4rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        border: 2px solid #f8f8f8;
        cursor: pointer;
        &:hover,&.active{
          border: 2px solid #5B97F0;
        }
        img{
          width: 100%;
          
        }
      }
    }
  }
  .products-info {
    background: #fff;
    font-size: 0.75rem;
    margin-top: 2rem;
    .headline{
      font-size: 1.4rem;
      padding-top: 2rem;
      padding-bottom: 0.2rem;
      margin-bottom: 2rem;
      border-bottom: 1px #dedede dashed;
    }
    .info{
      .item{
        // width: 49%;
        // margin-right:1%;
        margin-top:1rem;
        vertical-align: top;
        .title{
          font-size: 0.9rem;
          background: #f8f8f8;
          padding-left: 0.5rem;
        }
        .info-list{
          padding: 1rem 0.5rem;
          li{
            padding: 0.3rem 0;
            text-transform: capitalize;
             &.langlabel{
                .label{ width: 30%; }
                .text{ width: 70%; }
              }
            .label{
              display:inline-block;
              font-weight: bold;
              vertical-align: top;
              width: 18%;
             
            }
            .text{
              width: 82%;
              display: inline-block;
              vertical-align: top;
              padding-left: 1rem;
            }
          }
        }
      }
    }
  }
  .products-relative{
    font-size: 0.75rem;
    margin-top: 2rem;
    .headline{
      font-size: 1.4rem;
      padding-bottom: 0.2rem;
      margin-bottom: 1rem;
    }
    .relative-pro{
      .item{
        display: inline-block;
        vertical-align: top;
        width: 18%;
        margin-right: 2%;
        p{
          margin-top: 0.5rem;
        }
        a{
          width: 100%;
          display: inline-block;
          img{
            width: 100%;
            border: 1px solid #fff;
          }
          &:hover{
            img{
              opacity: 0.9;
            }
            p{
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>