<template>
  <div class="service-2 container-fluid3">
    <TitleInvSubtitle :title="title"/>
    <div class="flow-box">
      <img src="../assets/flow.svg" alt="" class="bg-flow-line">
        <ul class="items flow" >
        <li v-for="(item, index) in items" :key="index">
          <div class="item-img">
            <!-- <div class="number-box">
              <span class="number">{{index}}</span>
            </div> -->
            <img :src="item.icon" alt="">
          </div>
          <p>{{ item.title }}</p>
        </li>
      </ul>
    </div>
    <div class="flow-mobile">
      <div class="flow-mobile-content">
        <ul class="flow-left">
          <li v-for="(item, index) in itemsLeft" :key="index"> 
            <div class="box2" >
              <div class="step-head">
                <span class="index">{{item.index}}</span>
              </div>
              <p>{{item.title}}</p>
              <div class="line"></div>
            </div>
          </li>
        </ul>
        <ul class="flow-right">
          <li v-for="(item, index) in itemsRight" :key="index"> 
            <div class="box1" >
              <div class="step-head">
                <span class="index">{{item.index}}</span>
              </div>
              <p>{{item.title}}</p>
              <div class="line"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script> 
import TitleInvSubtitle from '../components/TitleInvSubtitle.vue'
import { get } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

import icon1 from '../assets/c-flow-01.svg'
import icon2 from '../assets/c-flow-02.svg'
import icon3 from '../assets/c-flow-03.svg'
import icon4 from '../assets/c-flow-04.svg'
import icon5 from '../assets/c-flow-05.svg'
import icon6 from '../assets/c-flow-06.svg'
import icon7 from '../assets/c-flow-07.svg'
import icon8 from '../assets/c-flow-08.svg'

export default {
  name: 'cservice',
  data() {
    return{
      title: {
        title: '',
        subtitle: ''
      },
      items: [
        { icon: icon1, title: '', index:1},
        { icon: icon2, title: '', index:2},
        { icon: icon3, title: '', index:3},
        { icon: icon4, title: '', index:4},
        { icon: icon5, title: '', index:5},
        { icon: icon6, title: '', index:6},
        { icon: icon7, title: '', index:7},
        { icon: icon8, title: '', index:8}
      ]
    }
  },
  components: {
    TitleInvSubtitle
  },
  computed: {
    itemsLeft:function(){
      return this.items.slice(0,4)
    },
    itemsRight:function(){
      return this.items.slice(4,8)
    }
  },
  methods: {
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.title.title = title['scTitle3']
        self.title.subtitle = title['scTitle4']
        
        for(var i=0;i<8; i++){
          self.items[i].title = title[`process${i+1}`]
        }
      })
    }
  },
  created: function(){
    const languageId = sessionStorage.getItem('language')
    this.getValue({lang: languageId, value: 'scTitle3;scTitle4;process1;process2;process3;process4;process5;process6;process7;process8;processD1;processD2;'})
  }
}
</script>

<style lang="scss">
.service-2 {
  padding-top: 3rem;
  padding-bottom: 3rem;
  .flow-box{
    width: 100%;
    position: relative;
    margin-top: 3rem;
    .bg-flow-line{
      width: 100%;
    }
  }
  .flow{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // height: 260px;
    // background: transparent url(../assets/flow.svg) no-repeat;
    // background-size: 100%;
  }
  .items {
    display: flex;
    justify-content: space-between;
    
    li {
      flex: 0 1 12.5%;
      text-align: center;
      position: relative;
      .item-img{
        width: 40%;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        img{
          width: 100%;
        }
        .number-box{
          width: 100%;
          &::before{
            content: '';
            display: inline-block;
            height: 2px;
            width: 100%;
            background: #1F87E8;
            position: absolute;
            top: 50%;
            left: 50%;
          }
        }
        .number{
          color: #1F87E8;
          display: inline-block;
          font-size: 0.8rem;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          border: 2px solid #1F87E8;
          background: #fff;
          position: relative;
          
          // &::before{
          //   content: '';
          //   display: inline-block;
          //   height: 2px;
          //   width: 100%;
          //   background: #1F87E8;
          //   position: absolute;
          //   top: 50%;
          //   left: -100%;
          // }
        }
      }
      &:last-child{
        .item-img{
          .number-box::before{
           height: 0;
         }
        }
      }
      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 0.75rem;
        text-transform: capitalize;
        font-weight: bold;
      }
      &:nth-child(even){
        .item-img {
          bottom: 8%;
        }
      }
      &:nth-child(odd){
        .item-img {
          top: 8%;
        }
      }
    }
  }
  .flow-mobile{
    display: none;
  }
  .flow-mobile-content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    ul{
      padding: 0 .5rem;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-flex-direction: column;
      flex-direction: column;
      width: 46%;
      li+li{
        .box2 .line{
          width: 2px;
          height: 1.35rem;
          background: #1f87e8;
          display: inline-block;
          position: absolute;
          right: .55rem;
          bottom: 2rem;
        }
        .box1 .line{
          width: 2px;
          height: 1.35rem;
          background: #1f87e8;
          display: inline-block;
          position: absolute;
          left: .55rem;
          bottom: 2rem;
        }
      }
      li{
        margin-bottom: .5rem;
        position: relative;
        .box2{
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
          -webkit-align-items: center;
          align-items: center;
          padding: .5rem 0;
          height: 2.4rem;
           p{
            font-size: .75rem;
            text-align: right;
            padding-right: .5rem;
          }
        }
        .box1{
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          flex-direction: row;
          -webkit-align-items: center;
          align-items: center;
          padding: .5rem 0;
          height: 2.4rem;
          p{
            font-size: .75rem;
            text-align: left;
            padding-left: .5rem;
          }
        }
        .step-head{
          position: relative;
          .index{
            width: 1.2rem;
            height: 1.2rem;
            display: inline-block;
            text-align: center;
            line-height: 1.5;
            font-size: .7rem;
            border-radius: 50%;
            font-weight: 700;
            border: 2px solid;
            color: #1f87e8;
            border-color: #1f87e8;
          }
        }
       
      }
    }
  }
}
</style>