<template>
<div class="about-us container-fluid4">
 <TitleInvSubtitle :title="title"/>
 <ul class="images">
   <li><img :src="img[0]" alt=""></li>
   <li><img :src="img[1]" alt=""></li>
   <li><img :src="img[2]" alt=""></li>
 </ul>
 <ul class="text">
   <li v-for="item in items" :key="item.index">
     <h3>{{item.title}}</h3>
     <p>{{item.des}}</p>
   </li>
 </ul>
 
</div>
</template>
<script>
import TitleInvSubtitle from '../components/TitleInvSubtitle.vue'

import { get} from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'
import img1zh from '../assets/Group1.svg'
import img2zh from '../assets/Group2.svg'
import img3zh from '../assets/Group3.svg'
import img1en from '../assets/Group1-en.svg'
import img2en from '../assets/Group2-en.svg'
import img3en from '../assets/Group3-en.svg'
import img1fr from '../assets/Group1-fr.svg'
import img2fr from '../assets/Group2-fr.svg'
import img3fr from '../assets/Group3-fr.svg'

export default {
  name: 'AboutUs',
  components: {
   TitleInvSubtitle 
  },
  data() {
    return {
      title: {
        title: '',
        subtitle: ''
      },
      img: [ img1zh,img2zh,img3zh],
      languageId: '',
      items: [
        { title: '', des: '' },
        { title: '', des: '' },
        { title: '', des: '' }
      ]
    }
  },
  methods: {
     getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const data = res.data.data[0].result.replace(/(R & D)|(R&D)/g, '*')
        const title = getQueryString(data)
        self.title.title = title['aTitle1']
       // console.log(title)
        self.title.subtitle = title['aD1'].replace(/\*/g, 'R & D')
        self.items[0].title = title['aTitle2']
        self.items[0].des = title['aD2'].replace(/\*/g, 'R & D')
        self.items[1].title = title['aTitle3']
        self.items[1].des = title['aD3']
        self.items[2].title = title['aTitle4']
        self.items[2].des = title['aD4']
      })
    }
  },
  created: function(){
    const languageId = this.languageId = sessionStorage.getItem('language')
    this.getValue({lang: languageId, value: 'aTitle1;aD1;aTitle3;aD3;aTitle2;aD2;aTitle4;aD4;'})
    if(languageId==0){
      this.img[0] = img1zh;
      this.img[1] = img2zh;
      this.img[2] = img3zh;
    }else if(languageId==2){
      this.img[0] = img1fr;
      this.img[1] = img2fr;
      this.img[2] = img3fr;
    }else{
      this.img[0] = img1en;
      this.img[1] = img2en;
      this.img[2] = img3en;
    }
  }
 
}
</script>
<style lang="scss">
.about-us {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 0.8rem;
  line-height: 2;
  .titlebar {
    .modified{
      opacity: 1;
      font-size: 0.9rem;
      text-transform: none;
      color: #666;
      margin-bottom: 3rem;
      &::after {
        background:#1F87E8;
        height: 0.3rem;
        width: 4rem;
        border-radius:0.15rem;
        top: 1.3rem;
      }
    }
    .subtitle {
      line-height: 2;
      font-size: 0.8rem;
    }
  }
  .images {
    width: 60%;
    margin: 0 auto;
    li{
      width: 33.3%;
      display: inline-block;
      padding: 6%;
      img{
        width: 100%;
      }
    }
  }
  .text {
    margin-top: 2rem;
    li {
      
      padding: 1rem 0;
      h3 {
        font-size: 1.2rem;
        font-weight: normal;
        //text-transform: capitalize;
      }
      p{
        opacity: 0.7;
      }
    }
  }

}
</style>