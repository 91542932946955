<template>
  <ul class="products-list container-fluid2" v-if="data0">
    <li class="item" v-for="(item, index) in data0" :key="index">
      <router-link :to="path+item.id">
        <div class="img-box">
          <h4>{{item.number}}</h4>
          <img :src="item.img" alt="">
        </div>
        <p>{{item.title}}</p>
      </router-link>
    </li>
    <!-- <li class="item">
      <router-link to="/products/info/1">
        <div class="img-box">
          <h4>Orca-50 Lite</h4>
          <img src="../assets/pro-example.png" alt="">
        </div>
        <p>RFID超高频手持移动终端Orca-50 Lite</p>
      </router-link>
    </li> -->
  </ul>
</template>

<script>
import { get,imageFile } from '../plugins/request.js'
export default {
  data() {
    return {
      data0:'',
      lanaguageId: '',
      path: ''
    }
  },
  created(){
    const self = this;
    const id = this.$route.params.id;
    const lanaguageId = this.lanaguageId = sessionStorage.getItem('language');
    this.path = sessionStorage.getItem('spath')+'/products/info/';
    
    get('/RFID-ProductsList.jsp', {langtype:lanaguageId,categoryid:id}).then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      let arr = []
      for(var i =0; i<len; i++){
        let obj1= {};
        obj1.number = data0[i]['skt44.skf490'];
        obj1.id = data0[i]['skt44.skf487']
        obj1.title = data0[i]['skt44.skf480']
        obj1.img = imageFile(data0[i]['skt44.skf489'])
        arr[i] = obj1
      }
      self.data0 = arr;
    })
  }
}
</script>

<style lang="scss">

  .products-list{
    background: #f8f8f8;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .item{
      display: inline-block;
      width: 23%;
      margin: 2% 1%;
      font-size: 0.8rem;
      vertical-align: top;
      color: #444444;
      &:hover{
        .img-box{
           box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, 0.1);
        }
        p{
          text-decoration: underline;
        }
      }
      .img-box{
        width: 100%;
        background: #fff;
        overflow: hidden;
        margin-bottom: 0.6rem;
        position: relative;
        padding: 1rem;
        h4{
          position: absolute;
          left: 4%;
          top: 4%;
        }
        img{
          width: 100%;
        }
      }
    }
  }
</style>