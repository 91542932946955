
import icon1 from '../assets/h_icon_工具柜.png'
import icon2 from '../assets/h_icon_资产.png'
import icon3 from '../assets/h_icon_档案.png'
import icon4 from '../assets/h_icon_智能柜.png'
import icon5 from '../assets/h_icon_巡检.png'
import icon6 from '../assets/h_icon_图书馆.png'
import icon7 from '../assets/h_icon_仓储.png'
import icon8 from '../assets/h_icon_联系我们.png'
import icon9 from '../assets/h_icon_公司介绍.png'

import example from '../assets/h_pro_手持机.png'
import { routes } from '../router/index.js'
import getQueryString from './getQueryString'

let spath = sessionStorage.getItem('spath');
let language = sessionStorage.getItem('language');

let navMenu= [
  { path: '/',  title: '首页','clickable': true},
  { path: '/products',  title: 'RFID产品', 'clickable': false,
    submenu: []
  },
  { path: '/solutions',title: '解决方案','clickable': false,
    submenu: []
  },
  { path: '/platform', title: '技术平台','clickable': true},
  { path: '/service',  title: '定制服务','clickable': true},
  { path: '/about',  title: '关于我们', 'clickable': false, footer: true,
    submenu:[
      {title:'关于我们', path: '/about',img:icon8},
      {title:'联系我们', path: '/contact',img:icon9},
    ]
  },
  { path: '/cases',title: '客户案例','clickable': true},
]

let productMenu = [];
let solutions = [];

import { get,imageFile } from '../plugins/request.js'


export async function getMainMenu(params){
  spath = sessionStorage.getItem('spath')
  return get('/get_value.jsp', params).then(function(res){
   
    const title = getQueryString(res.data.data[0].result)
    
    navMenu[0].title = title.hMenu1
    navMenu[0].path = spath+routes[0].alias  //首页
    navMenu[1].title = title.hMenu9
    navMenu[1].path = spath+routes[2].alias.replace('/:id', '')  //产品
    navMenu[2].title = title.hMenu4
    navMenu[2].path = spath+routes[4].alias.replace('/:id', '')  //解决方案
    navMenu[3].title = title.hMenu2
    navMenu[3].path = spath+routes[1].alias   //平台
    navMenu[4].title = title.hMenu5
    navMenu[4].path = spath+routes[5].alias   //技术服务
    navMenu[5].title = title.hMenu6
    navMenu[5].path = spath+routes[8].alias
    navMenu[5].submenu[0].title = title.hMenu6
    navMenu[5].submenu[0].path = spath+routes[8].alias    //关于我们
    navMenu[5].submenu[1].title = title.hMenu7
    navMenu[5].submenu[1].path = spath+routes[9].alias    //联系我们
    navMenu[6].title = title.hMenu8
    navMenu[6].path = spath+routes[7].alias     //客户案例
    //console.log(navMenu)
    return navMenu
  })
}


//解决方案
export function getSolutions(langtype){
  return new Promise((resolve, reject) => {
    let arr =[]
    get('/RFID-Solution.jsp', {langtype:langtype}).then(function(res){
      //console.log(res.data.data)
      const data0 = res.data.data;
      const len = data0.length;
      for(var i=0; i<len; i++){
        let obj= {};
        obj.title = data0[i]['skt49.skf545']
        obj.img = imageFile(data0[i]['skt49.skf546']) //header
        obj.id = data0[i]['skt49.skf544']
        obj.path = spath+'/solutions/'+data0[i]['skt49.skf544']
        arr[i] = obj
      }
      resolve(arr)
    })
  })
}


//产品
export function getProMenu(langtype){ 
  return new Promise((resolve,reject) => {
    let arr =[]
    get('/RFID-Menu.jsp', {langtype:langtype}).then(function(res){
        const data0 = res.data.data;
        const len = data0.length;
        for(var i =0; i<len; i++){
          var obj= {};
          obj.title = data0[i]['skt2.skf9']
          obj.img = imageFile(data0[i]['skt2.skf477'])
          obj.id = data0[i]['skt2.skf8']
          obj.path = spath+'/products/'+data0[i]['skt2.skf8']
          obj.submenu = []
          arr[i] = obj
        }
        resolve(arr)
    })
  })
}

function list (langtype, id){
  return new Promise((resolve,reject) => {
    let arr =[]
    get('/RFID-ProductsList.jsp', {langtype:langtype, categoryid:id}).then(function(res){
      const data1 = res.data.data;
      const len1 = data1.length;
      for(var j=0; j<len1; j++){
        let obj1 = {}
        obj1.number = data1[j]['skt44.skf480'];
        obj1.id = data1[j]['skt44.skf487'];
        obj1.path = spath+ '/products/info/' + data1[j]['skt44.skf487'];
        arr[j] = obj1
      }
      //console.log(id,arr)
      resolve(arr)
    })
  })
}

export async function getProducts(langtype){
  let result01 = await getProMenu(langtype);
  const len = result01.length;
  for(var i=0; i<len; i++){
    result01[i].submenu = await list (langtype,result01[i].id)
  }
  return result01
}

export async function getAllMenu(langtype) {
  getMainMenu({lang: langtype, value: 'hMenu1;hMenu9;hMenu2;hMenu4;hMenu5;hMenu6;hMenu7;hMenu8;'})
  navMenu[2].submenu = await getSolutions(langtype)
  let result01 = await getProMenu();
  const len = result01.length;
  for(var i=0; i<len; i++){
    result01[i].submenu = await list (langtype,result01[i].id)
  }
  navMenu[1].submenu = result01
  return navMenu
}
//getAllMenu(language)

export { navMenu }