const languageOptions =  [
  { value: '中文', path: '', id: 0},
  { value: 'English', path: '/en' , id: 1},
  { value: 'Français', path: '/fr', id: 2}
]

//获取浏览器的语言设置
const language0 = sessionStorage.getItem('language')

var jsSrc =(navigator.language || navigator.browserLanguage).toLowerCase();
const path = window.location.pathname
//console.log(jsSrc,path.replace('/','')=='' && !language0,path.replace('/','')==''&&language0)
//在首页 根据浏览器设置
if(path.replace('/','')==''){
  //jsSrc.indexOf('zh')>=0
  jsSrc = jsSrc.substr(0, 2)
 
  switch (jsSrc){
    case "zh":
      sessionStorage.setItem('language', languageOptions[0].id)
      sessionStorage.setItem('spath',  languageOptions[0].path)
      break;
    case "en":
      sessionStorage.setItem('language', languageOptions[1].id)
      sessionStorage.setItem('spath',  languageOptions[1].path)
      break;
    case "fr":
      sessionStorage.setItem('language', languageOptions[2].id)
      sessionStorage.setItem('spath',  languageOptions[2].path)
      break;
    default:
      sessionStorage.setItem('language', languageOptions[1].id)
      sessionStorage.setItem('spath',  languageOptions[1].path)
      break;
  }
 
}else{   //非首页的情况 判断是否带有语言路径的符号 针对手动输入的网址 
  var tempPath = path.match(/\/(en|fr)(\/)?/g)
  if(tempPath){
    tempPath = tempPath[0].replace(/\//g, '')
    switch (tempPath){
      case 'en': 
        sessionStorage.setItem('language', languageOptions[1].id)
        sessionStorage.setItem('spath',  languageOptions[1].path)
        break;
      case 'fr':
        sessionStorage.setItem('language', languageOptions[2].id)
        sessionStorage.setItem('spath',  languageOptions[2].path)
        break;
      default:
        sessionStorage.setItem('language', languageOptions[0].id)
        sessionStorage.setItem('spath',  languageOptions[0].path)
        break;
    }
  }else{
    sessionStorage.setItem('language', languageOptions[0].id)
    sessionStorage.setItem('spath',  languageOptions[0].path)
  }
}



// if(path.replace('/','')){
//   var
//   if(path.match(/^\/(en|fr)(\/)?/g)!=null){
//     sessionStorage.setItem('language', 1)
//     sessionStorage.setItem('spath',  '/en')
//     defaultLanguage5656565 = { id: 1, name: 'English', path: '/en', spath: '/en'}
//   }else{
//     sessionStorage.setItem('language', 0)
//     sessionStorage.setItem('spath',  '')
//     defaultLanguage5656565 = {id: 0, name: '中文', path: '/', spath: ''}
//   }
// }
