<template>
  <div class="solutions-item container-fluid2">
    <div class="left">
     <h2>{{title}}</h2>
     <div v-html="info" ></div>
     <!-- 我是暂时不要的 -->
     <!-- <div class="text-box">
       <h4>背景</h4>
       <p>固定资产管理是企业管理的一个重要组成部分，是完成生产经营任务的重要保障。企业资产具有价值高、使用周期长、使用地点分散，流动性强等特点，因此管理起来十分困难，每年资产清查往往要浪费大量的人力物力。对此，晨科研发出了专业、高效的企业RFID资产管理系统，该系统采用超高频RFID技术与软件技术结合的方式实现固定资产的日常管理。</p>
     </div>
     <div class="text-box">
       <h4>问题分析</h4>
       <p>1、管理混乱、账实不相符合<br>
        2、管理流程难以实时跟踪<br>
        3、资产状态、位置无法及时获悉<br>
        4、手工盘点繁琐易错、效率低下</p>
     </div>
     <div class="text-box">
       <h4>系统概述</h4>
       <p>晨科RFID资产管理系统覆盖了传统的固定资产管理的所有业务，包括固定资产采购验收、登记、变动、调拨、处置、盘点、统计、分析、帐表等各项功能，在每个需要管理的固定资产上安装RFID标签，在固定资产存放位置及可能发生流动的通道上安装RFID天线跟读写器，当固定资产发生移动时，系统自动查找变动单据，无单据移动触发实时报警，固定资产盘点时使用RFID手持设备快速盘点，或后台远程启动读写器进行自动盘点，读写器将读取的标签信息实时发送至后台服务器处理，实现盘点效率的大幅提升。</p>
       <img src="../assets/资产1.png" alt="">
       <p class="img-text">（网络架构）</p>
       <img src="../assets/资产2.png" alt="">
       <p class="img-text">（网络架构）</p>
     </div>
      <div class="text-box">
        <h4>系统优势</h4>
        <div>
          <h5>RFID标签打印</h5>
          <p>RFID电子标签内涵芯片和天线，利用RFID标签打印机在可视化打印的同时将数据内容写入芯片中存取，实现资产与RFID标签的批量快速绑定。</p>
        </div>
        <ul class="two-img">
          <li>
            <img src="../assets/资产1.png" alt="">
            <p class="img-text">（网络架构）</p>
          </li>
          <li>
            <img src="../assets/资产1.png" alt="">
            <p class="img-text">（网络架构）</p>
          </li>
        </ul>
        <h4>系统优势</h4>
        <div>
          <h5>RFID标签打印</h5>
          <p>RFID电子标签内涵芯片和天线，利用RFID标签打印机在可视化打印的同时将数据内容写入芯片中存取，实现资产与RFID标签的批量快速绑定。</p>
        </div>
        <ul class="two-img">
          <li>
            <img src="../assets/资产3.png" alt="">
            <p class="img-text">（网络架构）</p>
          </li>
          <li>
            <img src="../assets/资产3.png" alt="">
            <p class="img-text">（网络架构）</p>
          </li>
        </ul>
     </div> -->
     <!-- 暂时不要的终点 -->
    </div>
    <div class="right">
      <div class="deadline">
        <span class="title">{{titleParams[1]}}</span>
        <router-link to="/products/32" class="more">
          <span >{{titleParams[0]}}</span><img src="../assets/arrow-right.svg" alt="">
        </router-link>
      </div>
      <ul class="relative-pro">
        <li v-for="(item, index) in relativeProducts" :key="index">
          <router-link :to="path+item.id">
            <img :src="item.img" alt="">
            <p class="img-text">{{item.title}}</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { get, imageFile } from '../plugins/request.js'
import '../style/text-content-html.scss'
import getQueryString from '../plugins/getQueryString.js'
export default {
  data(){
    return{
     relativeProducts: [],
     title: '',
     info:'',
     titleParams: [],
     path: ''
    }
  },
  created(){
    const self = this;
    const id = this.$route.params.id;
    const languageId = sessionStorage.getItem('language')
    this.path = sessionStorage.getItem('spath')+'/products/info/';
    
    get('/RFID-SolutionDetails0.jsp', {langtype:languageId,Solutionid:id}).then(function(res){
      const data0 = res.data.data;
      self.title = data0[0]['skt50.skf555']
      self.info = data0[0]['skt50.skf556']
    })
    get('/RFID-SolutionDetails1.jsp', {langtype:languageId,Solutionid:id}).then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      let arr = []
      for(var i=0; i<len; i++) {
        let obj = {}
        obj.title = data0[i]['skt51.skf564']
        obj.img = imageFile(data0[i]['skt51.skf565'])
        obj.id = data0[i]['skt51.skf583']
        arr.push(obj)
      }
      console.log(arr)
      self.relativeProducts = arr
    })
    get('/get_value.jsp',{lang:languageId,value:"rMore;rRelatedPro;"}).then(function(data){
      const title = getQueryString(data.data.data[0].result)
      console.log(title)
      self.titleParams[0] = title.rMore
      self.titleParams[1] = title.rRelatedPro
    })
  }
}
</script>

<style lang="scss">
.solutions-item{
  background: #f8f8f8;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 0.75rem;
  line-height: 1.5;
  .left{
    display: inline-block;
    width: calc(98% - 17rem);
    background: #fff;
    vertical-align: top;
    padding: 2rem 1.5rem;
    h2{
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
      margin-bottom: 1rem;
    }
    .text-box{
      margin-bottom: 1rem;
      h4{
        font-size: 0.9rem;
        padding-bottom: 0.3rem;
        padding-top: 1rem;
        border-bottom: 1px dashed #dedede;
        margin-bottom: 1rem;
      }
      h5{
        font-size: 0.8rem;
        padding-bottom: 0.3rem;
      }
      img{
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .img-text{
        text-align: center;
      }
      .two-img{
        li{
          width: 49%;
          margin-right: 1%;
          display: inline-block;
        }
      }
    }
  }
  .right{
    width: 17rem;
    margin-left: 2%;
    
    display: inline-block;
    vertical-align: top;
    .deadline{
      padding: 1rem;
      background: #fff;
      // border-bottom: 1px solid #dedede;
      .title{
        font-size: 0.9rem;
      }
      .more{
        float: right;
        padding-top: 0.2rem;
        color:#666666;
        font-size:0.7rem;
        img{
          vertical-align: -6%;
        }
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .relative-pro{
      li{
        padding: 0 1rem 1rem 1rem;
        margin-top: 1rem;
        background: #fff;
        img{
          width: 100%;
        }
        // p{
        //   text-align: center;
        // }
        &:hover{
          img{opacity: 0.9;}
          p{
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>