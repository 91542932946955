<template>
  <div class="our-solutions">
    <div class="banner-middle">
      <div class="text">
        <img src="../assets/banner-title.png" alt="" class="banner-title" v-if="languageId==0">
        <img src="../assets/banner-title-en.png" alt="" class="banner-title" v-else>
        <p>{{bannerDesc}}</p>
      </div>
      <img src="../assets/banner-middle.png" alt="" class="banner-bg">
    </div>
     <TitleInvSubtitle :title="title"/>
     <ul class="solutions-wrapper container-fluid2">
       <li class="solutions-items" v-for="(item, index) in data0" :key="index">
         <router-link :to="'/solutions/'+item.id">
           <img :src="item.img" alt="">
           <p>{{item.title}}</p>
         </router-link>
       </li>
       <li class="solutions-items">
         <img src="../assets/s-more.png" alt="">
         <p>{{waitMore}}</p>
       </li>
     </ul>
  </div>
</template>

<script>
import TitleInvSubtitle from './TitleInvSubtitle.vue'
import { get,imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString'

export default {
 data(){
    return {
      title: {
        title: '',
        subtitle: ''
      },
      languageId: 0,
      bannerDesc: '',
      waitMore: '',
      data0: [
        { title: '', img:'', id: '', desc:'' }
      ]
    }
  },
  components: {
    TitleInvSubtitle
  },
  created(){
    const self = this;
    this.languageId = sessionStorage.getItem('language')
    get('/RFID-Solution.jsp', {langtype:this.languageId}).then(function(res){
      const data0 = res.data.data;
      const len = data0.length;
      for(var i =0; i<len; i++){
       let obj= {};
       obj.title = data0[i]['skt49.skf545']
       obj.img = imageFile(data0[i]['skt49.skf586'])
       obj.id = data0[i]['skt49.skf544']
       self.data0[i] = obj
      }
    })
     get('/get_value.jsp',{lang:this.languageId,value:"rHDesc2;rHTitle3;rHDesc4;rHMore;"}).then(function(data){
      const title = getQueryString(data.data.data[0].result)
      self.bannerDesc = title.rHDesc2
      self.title.title = title.rHTitle3
      self.title.subtitle = title.rHDesc4
      self.waitMore = title.rHMore
    })
  }
}
</script>

<style lang="scss">
.our-solutions{
  background: #f8f8f8;
  padding-bottom: 3rem;
  .banner-middle{
    width: 100%;
    position: relative;
    padding-top: 2rem;
    .text{
      position: absolute;
      text-align: center;
      width: 100%;
      top: 55%;
      transform: translateY(-55%);
      color: #fff;
      p{
        font-size: 1rem;
        margin-top: 1rem;
        font-weight: normal;
      }
    }
    .banner-bg{
      width: 100%;
    }
  }
  .solutions-wrapper{
    display: flex;
    flex-wrap: wrap;
    .solutions-items{
      box-sizing: border-box;
      width: 24%;
      background: #fff;
      margin: 0.5% 0.5% 1rem 0.5%;
      // display: inline-block;
      text-align: center;
      overflow: hidden;
      img{
        width: 100%;
        &:hover{
         opacity: 0.9;
         cursor: pointer;
        }
      }
      p{
        line-height: 1.6;
        font-size: 0.8rem;
        padding: 0.6rem 0.5rem 0.8rem 0.5rem;
      }
    }
  }
}

</style>