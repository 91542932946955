<template>
  <div class="service-1 container-fluid3">
    <TitleInvSubtitle :title="title"/>
    <ul class="items">
      <li v-for="item in items" :key="item.index">
        <img :src="item.image" class="cover"/>
        <div class="text">
          <h2>{{ item.title }}</h2>
          <p> {{ item.description }} </p>
        </div>
        <div class="card-footer">
          <img :src="item.icon" alt="" />
          <h4>{{ item.modify}}</h4>
        </div>
      </li>
    </ul>
  </div>
</template>
<script> 
import TitleInvSubtitle from '../components/TitleInvSubtitle.vue'
import { get } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

import img1 from '../assets/custom03.png'
import img2 from '../assets/custom01.png'
import img3 from '../assets/custom02.png'

import icon1 from '../assets/custom1.svg'
import icon2 from '../assets/custom2.svg'
import icon3 from '../assets/custom3.svg'

export default {
  name: 'cservice',
  data() {
    return{
     title: {
        title: '',
        subtitle: ''
      },
      items: [
        { icon: icon1, modify: 'DEMAND', image: img1, title: '', description: '' },
        { icon: icon2, modify: 'RAPIDITY', image: img2, title: '',description:''},
        { icon: icon3, modify: 'STABILIZATION', image: img3, title: '', description: '' }
      ],
      itemsZH: [
        { icon: icon1, modify: 'DEMAND', image: img1, title: '按需定制', description: '当通用性产品无法满足用户的个性化需求时，二次开发是平衡通用软件与定制软件，共性需求与个性需求的最佳方案。' },
        { icon: icon2, modify: 'RAPIDITY', image: img2, title: '快速响应', description: '基于平台和产品的定制开发，能快速响应客户个性化需求，最大限度的缩短开发周期，有效降低开发成本，提高客户满意度。' },
        { icon: icon3, modify: 'STABILIZATION', image: img3, title: '稳定可靠', description: '晨科专注于组织信息化建设，在个性化开发上积累了丰富的开发经验和团队建设经验，拥有一套成熟的定制解决方案。'}
      ]
    }
  },
  components: {
    TitleInvSubtitle
  },
  methods: {
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.title.title = title['scTitle1']
        self.title.subtitle = title['scTitle2']
        self.items[0].title = title['sc1Title1']
        self.items[0].description = title['sc1D1']
        self.items[1].title = title['sc1Title2']
        self.items[1].description = title['sc1D2']
        self.items[2].title = title['sc1Title3']
        self.items[2].description = title['sc1D3']
      })
    }
  },
  created: function(){
   const languageId = sessionStorage.getItem('language')
    this.getValue({lang: languageId, value: 'scTitle1;scTitle2;sc1Title1;sc1Title2;sc1Title3;sc1D1;sc1D2;sc1D3;'})
  }
}
</script>
<style lang="scss">
.service-1 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 0.75rem;
  img{
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
  .items {
    display: flex;
    justify-content: space-around;
    flex-flow: row nowrap;
    padding-top: 0.8rem;
    li{
      flex: 0 1 30%;
      background: #fff;
      border-radius: 0.4rem;
      box-shadow: 0.2rem 0.3rem 1rem #dedede;
      box-sizing: border-box;
      padding: 0.5rem 0.5rem 2rem 0.5rem;
      position: relative;
      .cover {
        width: 100%;
      }
      .card-footer {
        
        position: absolute;
        border-top: 1px solid #ececec;
        padding-top: 0.5rem;
        padding-bottom: 1rem;
        width: calc( 100% - 1rem );
        bottom: 0;
        img {
          vertical-align: bottom;
          margin-right: 0.5rem;
        }
        h4 {
          display: inline-block;
          opacity: 0.2;
          text-transform: uppercase;
        }
      }
      .text {
        padding: 0.5rem;
        h2 {
          padding: 0.5rem 0;
          font-weight: normal;
           //text-transform: uppercase;
        }
        p {
          text-align: justify;
          margin-bottom: 1.5rem;
          hyphens:auto; //英文换行加-
          opacity: 0.7;
          min-height: 2.5rem;
        }
        .enp{
          height: 5.5rem;
        }
        
      }
    }
  }
}
</style>