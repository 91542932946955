<template>
  <div class="form-get-demo-zh container-fluid3">
    <h1>{{formTitle.title}}</h1>
    <p>{{formTitle.desc}}</p>
    <div class="demo-form form-get-demo-desktop">
      <el-form ref="form" :model="form" :label-width="labelWidth" :label-position="labelPosition">
        <el-form-item :label="formTitle.name" class="bitian" v-if="languageId==0">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item :label="formTitle.firstName" class="bitian" v-if="languageId!=0">
          <el-input v-model="form.firstName"></el-input>
        </el-form-item>
        <el-form-item :label="formTitle.lastName" class="bitian" v-if="languageId!=0">
          <el-input v-model="form.lastName"></el-input>
        </el-form-item>
        <el-form-item :label="formTitle.email" class="bitian" v-if="languageId!=0">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item :label="formTitle.country" class="bitian" v-if="languageId!=0">
          <el-input v-model="form.country"></el-input>
        </el-form-item>
        <el-form-item :label="formTitle.phone" class="bitian">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item :label="formTitle.city" class="bitian" v-if="languageId==0">
          <el-input v-model="form.city"></el-input>
        </el-form-item>
        <el-form-item :label="formTitle.companyName" class="bitian">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item :label="formTitle.comment">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="onSubmit">{{formTitle.submit}}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <div class="demo-form form-get-demo-mobile">
      <el-form ref="form" :model="form"  label-position="top">
        <el-form-item :label="formTitle.name" class="bitian">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item :label="formTitle.phone" class="bitian">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item :label="formTitle.city" class="bitian">
          <el-input v-model="form.city"></el-input>
        </el-form-item>
        <el-form-item :label="formTitle.companyName" class="bitian">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item :label="formTitle.comment">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="onSubmit">{{formTitle.submit}}</el-button>
        </el-form-item>
      </el-form>
    </div> -->
  </div>
</template>
<script>
  import axios from "axios"
  import { ElNotification } from 'element-plus';
  import { get } from '../plugins/request.js'
  import getQueryString from '../plugins/getQueryString'

  export default {
    name: 'GetMyDemoZh',
    data() {
      return {
        form: {
          name:'',
          firstName:'',
          lastName: '',
          email:'',
          phone:'',
          city:'',
          companyName: '',
          country:'',
          desc: '',
          isPass: false,
          tip: ''
        },
        labelWidth: '8rem',
        labelPosition:'',
        languageId: '',
        formTitle: {
          title: '',
          desc: '',
          name: '',
          firstName:'',
          lastName: '',
          country: '',
          phone: '',
          comment: '',
          submit: '',
          city: '',
          email: '',
          companyName: ''
        }
      }
    },
    created(){
      const width = document.body.clientWidth
      if(width<=1000){
        this.labelWidth = '0'
        this.labelPosition = 'top'
      }else{
        this.labelWidth = '8rem'
        this.labelPosition = ''
      }

      this.languageId = sessionStorage.getItem('language')
      const self = this;
      const value = "getDemoTitle;getDemoDesc;formName;buttonSubmitName;formPhone;formFirstName;formLastName;formComment;formEmail;formCountry;formCompanyName;formCity;"
      get('/get_value.jsp',{lang:this.languageId,value:value}).then(function(data){
        const title = getQueryString(data.data.data[0].result)
        console.log(title)
        self.formTitle.title = title.getDemoTitle
        self.formTitle.desc = title.getDemoDesc
        self.formTitle.name = title.formName
        self.formTitle.firstName = title.formFirstName
        self.formTitle.lastName = title.formLastName
        self.formTitle.country = title.formCountry
        self.formTitle.companyName = title.formCompanyName
        self.formTitle.phone = title.formPhone
        self.formTitle.comment = title.formComment
        self.formTitle.submit = title.buttonSubmitName
        self.formTitle.city = title.formCity
        self.formTitle.email = title.formEmail
      })
    },
    methods: {
      chenkZh(){
        const form = this.form;
       
        if(form.name.replace(/\s*/g, "") ==''){  //不为空 
          form.isPass = false
          form.tip = '姓名不能为空'
          return
        }else if(!/^1[3|4|5|7|8][0-9]{9}$/.test(form.phone)){
          form.isPass = false
          form.tip = '请填写正确的手机号'
          return
        }else if(form.city.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '城市不能为空'
          return
        }else if(form.companyName.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '公司名称不能为空'
          return
        }else{
          form.isPass = true
        }
      },
      chenkEn(){
        const form = this.form;
        if(form.firstName.replace(/\s*/g, "") ==''){  //不为空 
          form.isPass = false
          form.tip = '"First name" can\'t be empty'
          return
        }else if(form.lastName.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '"Last name" can\'t be empty'
          return
        }else if(!/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(form.email)){
          form.isPass = false
          form.tip = '"Email" is not right'
          return
        }else if(form.country.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '"Country" can\'t be empty'
          return
        }else if(form.companyName.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '"companyName" can\'t be empty'
          return
        }else if(form.phone.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '"Phone" can\'t be empty'
          return
        }else{
          form.isPass = true
        }
      },
      chenkFr(){
        const form = this.form;
        if(form.firstName.replace(/\s*/g, "") ==''){  //不为空 
          form.isPass = false
          form.tip = '"Nom de famille" ne peut pas être vide'
          return
        }else if(form.lastName.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '"Prénom" ne peut pas être vide'
          return
        }else if(!/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(form.email)){
          form.isPass = false
          form.tip = '"E-mail" n\'est pas correct'
          return
        }else if(form.country.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '"Pays" ne peut pas être vide'
          return
        }else if(form.companyName.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '"Nom de la compagnie" ne peut pas être vide'
          return
        }else if(form.phone.replace(/\s*/g, "") ==''){
          form.isPass = false
          form.tip = '"Téléphoner" ne peut pas être vide'
          return
        }else{
          form.isPass = true
        }
      },
      check() {
        if(this.languageId==0){
          this.chenkZh()
        }else if(this.languageId==2){
          this.chenkFr()
        }else{
           this.chenkEn()
        }
        
      },
      submitEn(){
        const form = this.form;
        const self = this;
        axios.get('https://hz.chenksoft.com/ckapi/api/1/v2/add_customer_information.jsp',{
          params: {
            token: "chenksoft!@!",
            customer_name: form.firstName+' '+form.lastName,
            cellphone_number: form.phone,
            city: form.country,
            corporate_number: form.companyName,
            email: form.email,
            note: form.desc,
          }
        }).then(function(response){
          if(response.data.code==0){
            const messageName = (self.languageId==2)?'Soumission réussie！':'Submitted successfully!'
            const titleName = (self.languageId==2)?'Le succès':'success'
            self.$notify({
              title: titleName,
              message: messageName,
              type: 'success'
            });
          }else{
            const titleName = (self.languageId==2)?'l’échec':'success'
            const messageName = (self.languageId==2)?'La candidature a échoué, essayez de nouveau plus tard！':'Application failed, please try again later!'
            self.$notify.error({
              title: titleName,
              message: messageName,
            });
          }
          //清空
          self.form.firstName = ''
          self.form.lastName = ''
          self.form.phone = ''
          self.form.email = ''
          self.form.companyName = ''
          self.form.country = ''
          self.form.desc = ''
        })
      },
      submitZh(){
         const form = this.form;
        const self = this;
        axios.get('https://hz.chenksoft.com/ckapi/api/1/v2/add_customer_information.jsp',{
          params: {
            token: "chenksoft!@!",
            customer_name: form.name,
            cellphone_number: form.phone,
            city: form.city,
            corporate_number: form.companyName,
            note: form.desc,
          }
        }).then(function(response){
          
          if(response.data.code==0){
            ElNotification.success({
              title: '成功',
              message: '您已提交成功',
              type: 'success'
            });
          }else{
              ElNotification.error({
              title: '失败',
              message: '申请失败，请您稍后再试！',
            });
          }
          //清空
          self.form.name = ''
          self.form.phone = ''
          self.form.companyName = ''
          self.form.city = ''
          self.form.desc = ''
        })
      },
      notify(title){
        ElNotification.warning({
          title: title,
          message: this.form.tip,
          type: 'warning'
        });
      },
      onSubmit() {
       
        this.check()
        
        if(!this.form.isPass){
          const noticeName = (this.languageId==2)?'Une attention':'Notice'
          ElNotification.warning({
            title: noticeName,
            message: this.form.tip,
            type: 'warning'
          });
        }else{
          //console.log('提交')
          if(this.languageId==0){
            this.submitZh()
          }else{
            this.submitEn()
          }
        }
      }
    },
    mounted () {
      const self = this;
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.body.clientWidth
          
          if(window.screenWidth<=1000){
            self.labelWidth = '0'
            self.labelPosition = 'top'
          }else{
            self.labelWidth = '8rem'
            self.labelPosition = ''
          }
        })()
      }
    }
  }
</script>
<style lang="scss">
.form-get-demo-zh{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.form-get-demo-mobile{
  display: none;
}
// .form-get-demo-desktop{
//   width: 30rem;
//   margin: 2rem auto;
// }
.form-get-demo-zh{
  h1{
    font-weight: normal;
    font-size: 1.5rem;
  }
  p {
    font-size: 0.8rem;
    opacity: 0.6;
    margin-top: 0.5rem;
    line-height: 1.6;
  }
  .demo-form{
    padding: 3rem 0;
    width: 30rem;
    margin: 0 auto;
  }
  .bitian{
    .el-form-item__label{
      width: 6rem;
      &::after{
        content:' *';
        color: red;
      }
    }
  }
   .el-button--primary{
    width: 9rem;
  }
}
</style>