import { createApp } from 'vue'
import App from './App.vue'

import router from './router'

import './plugins/element.js'
import './plugins/language.js'
import './style/element-variables.scss';
import './style/media.scss';

import installElementPlus from './plugins/element'

import { addFontsize }from './plugins/resetFontSize'

addFontsize()

const app = createApp(App)
installElementPlus(app)
app.use(router).mount('#app')