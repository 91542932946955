<template>
  <div class="h-cases">
    <TitleInvSubtitle :title="title"/>
    <ul class="container-fluid2" v-if="languageId==0">
      <li v-for="item in items" :key="item.index">
        <img :src="item.logo" alt="">
      </li>
    </ul>
    <ul class="container-fluid2" v-else>
      <li v-for="item in itemsEn" :key="item.index">
        <img :src="item.logo" alt="">
      </li>
    </ul>
  </div>
</template>

<script>
import TitleInvSubtitle from './TitleInvSubtitle.vue'


import logo1 from '../assets/case-阿斯.png';  
import logo2 from '../assets/case-月星.png';  import logo4 from '../assets/case-银江2.png'; import logo5 from '../assets/case-浙江.png';
import logo6 from '../assets/case-p.png'; import logo7 from '../assets/case-知味观.png'; import logo8 from '../assets/case-bank.png';
import logo9 from '../assets/case-w.png'; import logo10 from '../assets/case2-大搜车.png';
import logo11 from '../assets/case3-工业.png'; import logo12 from '../assets/case4-华大.png';
import logo13 from '../assets/case5-华东理工.png'; import logo14 from '../assets/case6-华东医药.png';
import logo15 from '../assets/case-boe.png'; import logo16 from '../assets/case8-京.png';
import logo18 from '../assets/case10-中石化.png';import logo17 from '../assets/case-电网.png';
import logo19 from '../assets/case12-中国铁建.png'; import logo20 from '../assets/case13-中国石油.png';
import logo21 from '../assets/case15-中国地质大学.png'; import logo22 from '../assets/case16-中国东方航空.png';
import logo23 from '../assets/case17-中储粮.png'; import logo24 from '../assets/case18-上海.png';
import logo25 from '../assets/case19-二所.png'; 

import logo1en from '../assets/case-feisen.png';  
import logo2en from '../assets/case-sankyo.png';  
import logo5en from '../assets/case-jinbin.png';  
import logo6en from '../assets/case-meilejia.png';  
import logo7en from '../assets/case-ppg.png';  
import logo8en from '../assets/case-nidec.png';  
import logo9en from '../assets/case-huvitz.png';  
import logo13en from '../assets/case-boke.png';  
import logo14en from '../assets/case-thermo.png';  
import logo15en from '../assets/case-green-valley.png';  
import logo16en from '../assets/case-career.png';  
import logo17en from '../assets/case-tyk.png';  
import logo24en from '../assets/case-gke.png';  
import logo25en from '../assets/case-yena.png';  
import logo26en from '../assets/case-ferrotec.png';  
import logo27en from '../assets/case-sikeluo.png';  
import logo28en from '../assets/case-weber.png';  
import logo29en from '../assets/case-lekaibao.png';  

import logo31en from '../assets/case-xinyue.png';  
import logo32en from '../assets/case-warner.png';  
import logo33en from '../assets/case-hengsi.png';


import { get,imageFile } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString'

export default {
data(){
    return {
      title: {
        title: '',
        subtitle: ''
      },
      itemsEn: [
        { logo: logo1 },{ logo: logo14en },{ logo: logo5en },{ logo: logo15 },{ logo: logo26en },
        { logo: logo28en },{ logo: logo27en },{ logo: logo29en },{ logo: logo24en },{ logo: logo25en },
        { logo: logo7en },{ logo: logo8en },{ logo: logo31en },
        { logo: logo32en },{ logo: logo9 },
        { logo: logo16en },{ logo: logo33en },{ logo: logo2en },{ logo: logo1en },{ logo: logo13en },
        { logo: logo6en },{ logo: logo9en },{ logo: logo15en },{ logo: logo17en },
      ],
      languageId: '',
      items: [
        { logo: logo1 },{ logo: logo4 },{ logo: logo5 },{ logo: logo19 },{ logo: logo20 },
        { logo: logo22 },{ logo: logo21 },{ logo: logo23 },{ logo: logo24 },{ logo: logo25 },
        { logo: logo7 },{ logo: logo8 },{ logo: logo10 },
        { logo: logo11 },{ logo: logo14 },
        { logo: logo16 },{ logo: logo18 },{ logo: logo2 },{ logo: logo12 },{ logo: logo13 },
        { logo: logo6 },{ logo: logo9 },{ logo: logo15 },{ logo: logo17 },
      ]
    }
  },
  components: {
    TitleInvSubtitle
  },
  created() {
    const self = this;
    const languageId = this.languageId = sessionStorage.getItem('language')
    get('/get_value.jsp',{lang:languageId,value:"rHTitle5;rHDesc5;"}).then(function(data){
      const title = getQueryString(data.data.data[0].result)
      console.log(title)
      self.title.title = title.rHTitle5
      self.title.subtitle = title.rHDesc5
    })
  }
}
</script>

<style lang="scss">

.h-cases{
  background: #fff url(../assets/bg-f.png) center bottom no-repeat;
  padding-bottom: 3rem;
  ul{
    li{
      width: 15%;
      margin: 0.83%;
      padding: 0.2rem 0.83%;
      display: inline-block;
      background: #fff;
      border-radius: 2px;
      img{
        width: 100%;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }
    }
  }
}
</style>