<template>
 <div class="platform-3 container-fluid3">
   <TitleInvSubtitle :title="title"/>
   <ul class="items">
     <li v-for="item in items" :key="item.index">
       <div class="imgbox"><div class="title"><div class="cell"><p>{{ item.title }}</p></div></div><img :src="item.image" alt=""></div>
       <div class="text">
         <p>{{ item.description }}</p>
       </div>
     </li>
   </ul>
 </div>
</template>
<script>
import TitleInvSubtitle from '../components/TitleInvSubtitle.vue'
import { get} from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

import img1 from '../assets/platform3-1.png'
import img2 from '../assets/platform3-2.png'
import img3 from '../assets/platform3-3.png'
import img4 from '../assets/platform3-4.png'

export default {
  name: 'PFEfficiency',
  components: {
    TitleInvSubtitle
  },
  data() {
    return {
      title: {
        title: '',
        subtitle: ''
      },
      items: [
        { image: img1, title: '', description: '' },
        { image: img2, title: '', description: '' },
        { image: img3, title: '', description: '' },
        { image: img4, title: '', description: '' },
      ],
    }
  },
   methods: {
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.title.title = title['p3Title1']
        self.title.subtitle = title['p3Title2']
        for(var i=0;i<4; i++){
          self.items[i].title = title[`p3Title${i+3}`]
          self.items[i].description = title[`p3D${i+3}`]
        }
      })
    }
  },
  created: function(){
    const languageId = sessionStorage.getItem('language')
    this.getValue({lang: languageId, value: 'p3Title1;p3Title2;p3Title3;p3D3;p3Title4;p3D4;p3Title5;p3D5;p3Title6;p3D6;'})
  }
  
}
</script>

<style lang="scss">
.platform-3{
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size:0.7rem;
  .items {
    li {
      display: inline-block;
      width: 23%;
      margin: 1%;
      vertical-align: top;
      text-align: left;
      margin-bottom: 2rem;
      box-sizing: border-box;
      .imgbox{
        overflow: hidden;
        border-radius: 0.2rem;
        position: relative;
        .title {
          display: table;
          width: 100%;
          height: 100%;
           position: absolute;
            display: flex;
            align-items: center;
          .cell{
           width: 100%;
            vertical-align: middle;
            font-weight: bold;
            text-align: center;
            color: #fff;
            font-size: 0.9rem;
            p{
              text-align: center;
            }
          }
        }
      }
      img {
        width: 100%;
        max-width: 100%;
      }
      .text{
        p {
          opacity: 0.6;
          line-height: 1.6;
          margin-top: 0.5rem;
        }
      }
    }
  }
}
</style>