<template>
  <div class="platform-2">
    <div class="container-fluid3">
      <TitleInvSubtitle :title="title"/>
      <ul class="wrapper" >
        <li v-for="item in items" :key="item.index" >
          <div class="title clearfix">
            <div>
              <img :src="item.icon" alt="">
            </div>
            <h3>{{ item.title }}</h3>
          </div>
          <p class="desciption">{{ item.description }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import TitleInvSubtitle from '../components/TitleInvSubtitle.vue'
import { get } from '../plugins/request.js'
import getQueryString from '../plugins/getQueryString.js'

import icon1 from '../assets/core1.png'
import icon2 from '../assets/core2.png'
import icon3 from '../assets/core3.png'
import icon4 from '../assets/core4.png'
import icon5 from '../assets/core5.png'

export default {
  components: {
    TitleInvSubtitle
  },
  data() {
    return {
      title: {
        title: '',
        subtitle: ''
      },
      items: [
        { icon: icon1, title:'', description: ''},
        { icon: icon2, title:'', description: ''},
        { icon: icon3, title:'', description: ''},
        { icon: icon4, title:'', description: ''},
        { icon: icon5, title:'', description: ''},
      ],
      itemsZH: [
        {icon: icon1, title:'统一用户认证', description: '实现各应用系统用户、岗位和组织机构的统一管理，支持跨终端单点登录和统一身份认证，用户登录后转入到其他应用系统时不需要再次登录，保证用户在不同的应用系统中身份的一致性。'},
        {icon: icon2, title:'统一应用管理', description: '基于统一的应用管理平台，能帮助IT部门更好地管理维护各类应用系统，实现应用的快速发布、灵活控制、动态监测和统一管理，保证应用的持续交付以及业务的不间断运行，提高IT响应速度。'},
        {icon: icon3, title:'统一流程构建', description: '提供强大灵活的工作流引擎，支持用户基于组织业务模型和管理模式，自行定义所需要的各种流程应用，快速构建符合自身业务的管控体系，为建设组织整体协同平台夯实基础。'},
        {icon: icon4, title:'统一数据中心', description: '建设统一的数据中心，用来管理组织数据，保证数据的完整、一致和准确。并通过开发数据服务应用，利用业务数据快速地构建 BI 报表、数据大屏，建设场景化的数据产品来支持业务的决策。'},
        {icon: icon5, title:'统一开发平台', description: '强大的开发运维一体化工具，基于微服务、可视化、云开发技术，提供从设计、开发、部署、运维的一体化支持。支持敏捷定制、快速开发，能满足个性化需求，快速构建和扩展符合业务的应用。'},
      ]
    }
  },
  methods: {
    getValue(params) {
      const self = this;
      get('/get_value.jsp', params).then(function(res){
        //console.log(res.data.data[0].result)
        const title = getQueryString(res.data.data[0].result)
        self.title.title = title['p2Title1']
        self.title.subtitle = title['p2Title2']
        for(var i=0;i<5; i++){
         
          self.items[i].title = title[`p2Title${i+3}`]
          self.items[i].description = title[`p2D${i+3}`]
        }
      })
    }
  },
  created: function(){
    const languageId = sessionStorage.getItem('language')
    this.getValue({lang: languageId, value: 'p2Title1;p2Title2;p2Title3;p2D3;p2Title3;p2D3;p2Title4;p2D4;p2Title5;p2D5;p2Title6;p2D6;p2Title7;p2D7;'})
  }
}
</script>
<style lang="scss">
.platform-2 {
  padding-bottom: 2rem;
  padding-top: 1rem;
  background: #f8f8f8;
  font-size: 0.75rem;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      vertical-align: top;
      width:31.3%;
      padding: 1rem 1.5rem;
      margin: 1%;
      box-sizing: border-box;
      background: #fff;
      border-radius: 0.2rem;
      .title {
        display: flex;
        justify-content: center;
        img{
          width: 2.4rem;
        }
        h3 {
          font-size: 1rem;
          //text-transform: uppercase;
          font-weight: normal;
          margin-top: 0.5rem;
        }
      }
      .desciption {
        opacity: 0.8;
        hyphens: auto; //英文换行加-
        text-align: justify;
        line-height: 1.6;
      }
    }
  }
}
</style>